<template>
    <div class="toplevel">
        <h1>{{ heading }}</h1>

        <div class="card main-container">
            <div class="card-body">
                <slot :name="currentPageName"></slot>
            </div>
        </div>

        <div class="button-box">
            <button
                class="btn btn-primary button-box-button"
                @click="nextClick"
                :disabled="!nextButtonEnabled"
            >
                {{ nextButtonName }}
            </button>
            <button
                class="btn btn-secondary button-box-button"
                v-if="!isFirstPage"
                @click="popPage"
            >
                Back
            </button>
        </div>
    </div>
</template>

<style scoped>
.testClass {
    display: None;
}

.toplevel {
    margin-left: auto;
    margin-right: auto;
}

h1 {
    margin-bottom: 2rem;
}

.button-box {
    margin-top: 2rem;
}

@media (max-width: 40rem) {
    .button-box {
        display: flex;
        flex-direction: column;
    }

    .button-box-button {
        margin-bottom: 0.6rem;
    }
}

@media (min-width: 40rem) {
    .button-box {
        max-width: 40rem;
        margin-left: auto;
        margin-right: auto;

        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .button-box-button {
        min-width: 14rem;
    }
}
</style>

<script>
export default {
    name: "Wizard",
    props: {
        heading: {
            type: String,
            default: ""
        },
        initialPageName: {
            type: String,
            required: true
        },
        pageInfos: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            navigationStack: [],
            nextButtonEnabled: true
        };
    },
    computed: {
        currentPageName() {
            return this.navigationStack[this.navigationStack.length - 1];
        },
        isFirstPage() {
            return this.navigationStack.length == 1;
        },
        nextButtonName() {
            let result = this.currentPageInfo.nextButtonName;

            if (result == undefined) {
                return "Next";
            }

            return result;
        },
        currentPageInfo() {
            return this.pageInfos[this.currentPageName];
        }
    },
    methods: {
        pushPage(pageName) {
            this.navigationStack.push(pageName);
            this._finishNavigation();
        },
        popPage() {
            this.navigationStack.pop();
            this._finishNavigation();
        },
        returnToPage(pageName) {
            while (this.currentPageName != pageName) {
                this.navigationStack.pop();
            }

            this._finishNavigation();
        },
        _finishNavigation() {
            // Enable/disable the "next" button
            this.nextButtonEnabled = this.currentPageInfo.nextButtonEnabled;
            if (this.nextButtonEnabled == undefined) {
                this.nextButtonEnabled = true;
            }
        },

        setNextButtonEnabled(value) {
            this.nextButtonEnabled = value;
        },

        nextClick() {
            let action = this.currentPageInfo.action;

            if (action == undefined) {
                return;
            }

            if (typeof action === "string" || action instanceof String) {
                this.pushPage(action);
                return;
            }

            action(this);
        }
    },
    created() {
        this.navigationStack = [this.initialPageName];
    }
};
</script>
