<template>
    <nav class="navbar navbar-expand-sm navbar-dark bg-primary">
        <router-link :to="{ name: 'home' }" class="navbar-brand">
            <img src="/bot-logotype.png" alt="Bot Logo" class="logo-image" />
        </router-link>

        <button
            class="navbar-toggler nav-item"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <!-- <span class="navbar-toggler-icon"></span> -->
            Menu
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <!-- Logged Out -->
                <router-link
                    :to="{ name: 'register' }"
                    class="nav-item nav-link"
                    active-class="nav-active"
                    v-if="!loggedIn"
                    >Try For Free</router-link
                >
                <router-link
                    :to="{ name: 'login' }"
                    class="nav-item nav-link"
                    active-class="nav-active"
                    v-if="!loggedIn"
                    >Sign In</router-link
                >

                <!-- Logged in -->
                <router-link
                    :to="{ name: 'dashboard' }"
                    class="nav-item nav-link"
                    active-class="nav-active"
                    v-if="loggedIn"
                    >Dashboard</router-link
                >
                <router-link
                    :to="{ name: 'fortnite' }"
                    class="nav-item nav-link"
                    active-class="nav-active"
                    v-if="loggedIn"
                    >Fortnite</router-link
                >
                <router-link
                    :to="{ name: 'subscription' }"
                    class="nav-item nav-link"
                    active-class="nav-active"
                    v-if="loggedIn"
                    >Subscription</router-link
                >
                <router-link
                    :to="{ name: 'settings' }"
                    class="nav-item nav-link"
                    active-class="nav-active"
                    v-if="loggedIn"
                    >Settings</router-link
                >
                <!-- <router-link :to="{name: 'subscription'}" class="nav-item nav-link" active-class="nav-active" v-if="loggedIn">Subscription</router-link> -->
                <div
                    class="nav-item nav-link"
                    @click="logoutClick"
                    v-if="loggedIn"
                >
                    Sign Out
                </div>
            </ul>
        </div>
    </nav>
</template>

<style scoped>
.navbar-brand {
    height: 3rem;
}

.logo-image {
    height: 110%;
}

.navbar-toggler {
    height: 3rem;
    border: none;
    margin-right: 0.6rem;
}

.nav-item {
    color: white !important;
    font-size: 1.1rem;
    transition: background-color 0.2s;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0.2rem;
}

.nav-item:hover {
    background-color: var(--highlight-primary);
}

.nav-active {
    font-weight: bold;
    color: var(--light);
}
</style>

<script>
export default {
    name: "NotificationBar",
    computed: {
        loggedIn() {
            return this.$store.state.loggedIn;
        }
    },
    methods: {
        async logoutClick() {
            await this.$store.dispatch("logout");
        }
    }
};
</script>
