<template>
    <div class="toplevel-inventory-item">
        <!-- Failed accounts -->
        <div v-for="acc in accountInfos" :key="acc.epicAccountId">
            <div
                class="alert alert-danger"
                role="alert"
                v-if="acc.errorMessage != null"
            >
                Could not access {{ acc.displayName }}: {{ acc.errorMessage }}
            </div>
        </div>

        <!-- Heading -->
        <div v-if="!loading" class="mb-5">
            <!-- Done loading, but nothing was found -->
            <div
                v-if="Object.keys(itemsInCategories).length === 0"
                class="card"
            >
                <div class="card-body">
                    You either carry no items or don't have any Epic accounts
                    linked.
                    <br />
                    You can link accounts
                    <router-link :to="{ name: 'fortnite' }">here</router-link>.
                </div>
            </div>

            <!-- Remind everybody that not all items show up here -->
            <div v-else>
                <h3>
                    Showing all <b>valuable</b> items in your backpack and
                    storage.
                </h3>
            </div>
        </div>

        <!-- Summary -->
        <div class="card">
            <div class="card-body container">
                <div class="row">
                    <div class="col-6" />
                    <div class="col-3">
                        <h5># of valuables</h5>
                    </div>
                    <div class="col-3">
                        <h5>free space (roughly)</h5>
                    </div>
                </div>
                <div
                    class="row mt-2 summary-row"
                    v-for="accInfo in accountInfos"
                    :key="accInfo.displayName"
                    @click="e => onAccountClick(e, accInfo)"
                >
                    <img
                        v-if="accInfo.status !== 'disabled'"
                        class="col-1"
                        src="/icons/toggle2-on.svg"
                    />

                    <img
                        v-if="accInfo.status === 'disabled'"
                        class="col-1"
                        src="/icons/toggle2-off-custom.svg"
                    />

                    <div class="col-5">
                        {{ accInfo.displayName }}
                    </div>

                    <div v-if="accInfo.status === 'disabled'" class="col-6">
                        <i>Hidden - Press to show</i>
                    </div>

                    <div v-if="accInfo.status === 'loading'" class="col-6">
                        <span class="spinner-border spinner-border-sm"></span>
                    </div>

                    <div v-if="accInfo.status === 'error'" class="col-6">
                        Error: {{ accInfo.errorMessage }}
                    </div>

                    <div v-if="accInfo.status === 'ready'" class="col-3">
                        {{ accInfo.nStacksNoteworthy }}
                    </div>

                    <div v-if="accInfo.status === 'ready'" class="col-3">
                        {{
                            Math.max(
                                0,
                                accInfo.backpackCapacity +
                                    accInfo.storageCapacity -
                                    accInfo.nStacksTotal
                            )
                        }}
                    </div>
                </div>
            </div>
        </div>

        <!-- Items -->
        <div
            v-for="(items, categoryName) in itemsInCategories"
            :key="categoryName"
        >
            <h3 class="mt-3">
                {{ categoryName }} - {{ formatAmount(categoryTotal(items)) }}
            </h3>
            <div class="list-group">
                <inventory-item
                    v-for="item in items"
                    :key="item.templateId"
                    :templateId="item.templateId"
                    :nicename="item.nicename"
                    :rarity="item.rarity"
                    :powerLevel="item.powerLevel"
                    :imageUrl="item.imageUrl"
                    :amounts="item.amounts"
                    class="list-group-item inventory-item"
                ></inventory-item>
            </div>
        </div>
    </div>
</template>

<style scoped>
.summary-row {
    transition: background-color 0.2s;
    cursor: pointer;
}

.summary-row:hover {
    background-color: gray;
}

.toplevel-inventory-item {
    max-width: 45rem;
    margin-left: auto;
    margin-right: auto;
}

.inventory-item {
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 0.4rem 1rem;
}

.inventory-item:hover {
    background-color: gray;
}

.inventory-item:first-child {
    padding-top: 1rem;
}

.inventory-item:last-child {
    padding-bottom: 1rem;
}
</style>

<script>
import { apiRequest } from "../main.js";
import InventoryItem from "../components/InventoryItem.vue";

const HIDDEN_ACCOUNTS_STORAGE_KEY = "fortniteInventoryViewHiddenAccounts";

export default {
    name: "FortniteInventoryView",
    components: {
        InventoryItem
    },
    data() {
        return {
            loading: true,
            // Query results for each linked account. Accounts whose queries
            // have failed are included as well, so error messages can be
            // displayed.
            // See the `mounted` method for details
            accountInfos: []
        };
    },
    computed: {
        itemsInCategories() {
            // Group identical items
            let groupedItems = {};

            // Fetch the inventory contents of all accounts
            for (var accInfo of this.accountInfos) {
                if (accInfo.status !== "ready") {
                    continue;
                }

                for (var rawItem of accInfo.items) {
                    let key = `${rawItem.templateId}:::${rawItem.level}`;

                    var group = groupedItems[key];
                    if (group === undefined) {
                        group = {
                            templateId: rawItem.templateId,
                            nicename: rawItem.nicename,
                            rarity: rawItem.rarity,
                            powerLevel: rawItem.powerLevel,
                            type: rawItem.type,
                            imageUrl: rawItem.imageUrl,
                            amounts: {}
                        };
                        groupedItems[key] = group;
                    }

                    group.amounts[accInfo.displayName] = rawItem.amount;
                }
            }

            // Divide into categories
            let categories = {};
            for (var item of Object.values(groupedItems)) {
                const categoryName = item.type;
                var category = categories[categoryName];

                if (category === undefined) {
                    category = [];
                    categories[categoryName] = category;
                }

                category.push(item);
            }

            // Per-category postprocessing
            const categoryNames = {
                ingredient: "Crafting Materials",
                trap: "Traps",
                worlditem: "Miscellaneous"
            };

            const unordered = {};
            for (let origName in categories) {
                let categoryName = categoryNames[origName] || origName;
                var categoryItems = categories[origName];

                // TODO use a better sort order. Something value based for example
                categoryItems = categoryItems.sort((a, b) =>
                    a.nicename.localeCompare(b.nicename)
                );

                unordered[categoryName] = categoryItems;
            }

            // Sort
            const ordered = Object.keys(unordered)
                .sort()
                .reduce((obj, key) => {
                    obj[key] = unordered[key];
                    return obj;
                }, {});

            return ordered;
        }
    },
    methods: {
        formatAmount(amount) {
            if (amount > 1e6) {
                return `${(amount / 1e6).toFixed(1)}M`;
            } else if (amount > 1e4) {
                return `${Math.floor(amount / 1000)}K`;
            } else {
                return `${amount}`;
            }
        },
        categoryTotal(items) {
            var result = 0;

            for (var item of items) {
                for (var amount of Object.values(item.amounts)) {
                    result += amount;
                }
            }

            return result;
        },
        async loadAccount(acc) {
            acc.status = "loading";

            const response = await apiRequest(
                "GET",
                `/api/fortnite/${acc.epicAccountId}/inventory`
            );

            if (response.success) {
                // Account could've been disabled in the meantime
                if (acc.status !== "disabled") {
                    acc.status = "ready";
                }
                acc.items = response.items;
                acc.nStacksTotal = response.nStacksTotal;
                acc.nStacksNoteworthy = response.nStacksNoteworthy;
                acc.backpackCapacity = response.backpackCapacity;
                acc.storageCapacity = response.storageCapacity;
            } else {
                acc.status = "error";
                acc.errorMessage = response.message;
            }
        },
        async setAccountEnabled(acc, enabled) {
            // Save the setting
            var hiddenAccounts = localStorage.getItem(
                HIDDEN_ACCOUNTS_STORAGE_KEY
            );
            if (hiddenAccounts === null) {
                hiddenAccounts = [];
            } else {
                hiddenAccounts = hiddenAccounts.split(",");
            }

            hiddenAccounts = hiddenAccounts.filter(
                accId => accId !== acc.epicAccountId
            );

            if (!enabled) {
                hiddenAccounts.push(acc.epicAccountId);
            }

            localStorage.setItem(
                HIDDEN_ACCOUNTS_STORAGE_KEY,
                hiddenAccounts.join(",")
            );

            // Disable
            if (enabled === false) {
                acc.status = "disabled";
                return;
            }

            // Enable
            if (acc.items === null) {
                await this.loadAccount(acc);
            }
            acc.status = "ready";
        },
        async onAccountClick(event, clickedAcc) {
            if (event.shiftKey) {
                for (var acc of this.accountInfos) {
                    this.setAccountEnabled(
                        acc,
                        acc.epicAccountId === clickedAcc.epicAccountId
                    );
                }
            } else {
                this.setAccountEnabled(
                    clickedAcc,
                    clickedAcc.status === "disabled"
                );
            }
        }
    },
    async mounted() {
        // Find disabled accounts
        var hiddenAccounts = localStorage.getItem(HIDDEN_ACCOUNTS_STORAGE_KEY);
        if (hiddenAccounts === null) {
            hiddenAccounts = "";
        }

        // Initialize all accounts
        let allAccounts = this.$store.state.linkedEpicAccounts;
        let selectedAccountId = this.$route.query.accountId;

        for (var acc of allAccounts) {
            // Honor the query parameter for selecting accounts
            if (
                selectedAccountId !== undefined &&
                selectedAccountId !== acc.epicAccountId
            ) {
                continue;
            }

            // Add the account
            acc = { ...acc };
            acc.status = "loading";
            acc.errorMessage = null;
            acc.items = null;
            acc.nStacksTotal = 0;
            acc.nStacksNoteworthy = 0;
            acc.backpackCapacity = 0;
            acc.storageCapacity = 0;

            // Hidden account?
            if (hiddenAccounts.indexOf(acc.epicAccountId) !== -1) {
                acc.status = "disabled";
            }

            this.accountInfos.push(acc);
        }

        // Fetch the items
        for (acc of this.accountInfos) {
            if (acc.status !== "disabled") {
                await this.loadAccount(acc);
            }
        }

        this.loading = false;
    }
};
</script>
