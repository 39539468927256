<template>
    <div class="card">
        <form class="container card-body">
            <img
                class="mb-4"
                src="bot_icon_color.png"
                alt=""
                width="90rem"
                height="90rem"
            />
            <h1 class="h3 mb-3 fw-normal">Sign In</h1>

            <div
                class="alert alert-danger"
                role="alert"
                v-if="alertText.length != 0"
            >
                {{ alertText }}
            </div>

            <label for="inputUsername" class="d-none">Username</label>
            <input
                type="text"
                id="inputUsername"
                class="form-control"
                placeholder="Username"
                v-model="username"
                required
                autofocus
                @keyup.enter="enterPressed"
            />

            <label for="inputPassword" class="d-none">Password</label>
            <input
                type="password"
                id="inputPassword"
                class="form-control"
                placeholder="Password"
                v-model="password"
                required
                @keyup.enter="enterPressed"
            />

            <button
                type="button"
                id="signInButton"
                class="w-100 btn btn-lg btn-primary"
                :disabled="loading || !fieldsValid"
                @click="clickSignIn"
            >
                <span v-if="!loading">Sign In</span>
                <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="loading"
                ></span>
            </button>
            <div>or</div>
            <router-link
                class="w-100"
                :to="{ name: 'register', query: { username } }"
                >Create an Account</router-link
            >
        </form>
    </div>
</template>

<style scoped>
#inputUsername,
#inputPassword {
    margin-bottom: 0.5rem;
}

#signInButton {
    margin-bottom: 0.8rem;
}
</style>

<script>
export default {
    name: "LoginForm",
    computed: {
        fieldsValid: function() {
            return this.username != "" && this.password != "";
        }
    },
    data: function() {
        return {
            alertText: "",
            loading: false,
            username: "",
            password: ""
        };
    },
    methods: {
        async enterPressed() {
            if (!this.loading && this.fieldsValid) {
                await this.clickSignIn();
            }
        },
        async clickSignIn() {
            // Reset state
            this.loading = true;
            this.alertText = "";

            // Login
            const response = await this.$store.dispatch(
                "loginWithUsernameAndPassword",
                {
                    username: this.username,
                    password: this.password
                }
            );

            if (!response.success) {
                this.alertText = response.message;
            }

            this.loading = false;
        }
    }
};
</script>
