<template>
    <div class="main-container">
        <div
            class="alert alert-danger"
            role="alert"
            v-show="generalAlert.length != 0"
        >
            {{ generalAlert }}
        </div>

        <div class="card">
            <div class="card-body">
                <h3>Posting</h3>

                <div class="alert alert-warning" role="alert" v-if="timeBetweenPosts < 45">Spamming too many listings can result in a warning or suspension by gameflip.</div>
                <setting-group
                    class="setting-group"
                    title="Time Between Posts"
                    description="How many seconds to wait between creating two listings."
                    :alert="alerts.timeBetweenPosts"
                >
                    <input
                        type="number"
                        min="10"
                        max="86400"
                        class="form-control"
                        v-model="timeBetweenPosts"
                    />
                </setting-group>

                <div class="alert alert-warning mt-4" role="alert" v-if="autoPurgeOption === 'older than hours...' && autoPurgeHours < 24">Rapidly deleting and re-posting listings can result in a warning or suspension by gameflip.</div>
                <setting-group
                    class="setting-group"
                    title="Auto Purge"
                    description="The bot can automatically remove old listings for you. Select which listings you want it to remove."
                    :alert="alerts.autoPurge"
                >
                    <select
                        name="autoPurge"
                        id="autoPurge"
                        class="form-control"
                        v-model="autoPurgeOption"
                    >
                        <option>expired</option>
                        <option>older than hours...</option>
                        <option>off</option>
                    </select>
                    <input
                        type="number"
                        min="1"
                        max="500"
                        class="form-control mt-1"
                        v-if="autoPurgeOption == 'older than hours...'"
                        v-model="autoPurgeHours"
                        placeholder="hours"
                    />
                </setting-group>

                <setting-group
                    class="setting-group"
                    title="Auto Set"
                    description="Automatically find the fastest post time that won't make you run out of listings."
                >
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="findOptimalSettings"
                    >
                        Find Optimal Settings
                    </button>
                </setting-group>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <h3>Gameflip API</h3>

                <setting-group
                    class="setting-group"
                    title="Change Account"
                    description="Linking a Gameflip account allows you to post listings to that account. Only one account can be linked at a time."
                >
                    <router-link
                        class="btn btn-primary"
                        :to="{
                            name: 'link-gameflip',
                            query: { setupUser: false }
                        }"
                    >
                        Change Gameflip Account
                    </router-link>
                </setting-group>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <h3>Account</h3>

                <setting-group
                    class="setting-group"
                    title="Change Username"
                    description="You can use this name to log into AutoFlipper. This does not have to be the same as your Gameflip name. Leave this empty to keep your old username."
                    :alert="alerts.username"
                >
                    <input
                        type="username"
                        class="form-control"
                        v-model="username"
                        placeholder="Unchanged"
                        autocomplete="off"
                    />
                </setting-group>

                <setting-group
                    class="setting-group"
                    title="Change Password"
                    description="The password you will use to log into AutoFlipper. Leave this empty to keep your old password."
                    :alert="alerts.password"
                >
                    <input
                        type="password"
                        class="form-control mb-1"
                        v-model="password1"
                        placeholder="Unchanged"
                        autocomplete="off"
                    />
                    <input
                        type="password"
                        class="form-control"
                        v-model="password2"
                        placeholder="Repeat Password"
                        autocomplete="off"
                    />
                </setting-group>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <h3>Product Defaults</h3>
                <div class="mb-4">
                    These settings will be used for new products, as well as
                    when posting a custom bundle.
                </div>

                <setting-group
                    class="setting-group"
                    title="Shipping Time"
                    description="In how many days you will ship orders."
                    :alert="alerts.defaultShipsInDays"
                >
                    <input
                        type="number"
                        min="1"
                        max="30"
                        class="form-control"
                        v-model="defaultShipsInDays"
                    />
                </setting-group>

                <setting-group
                    class="setting-group"
                    title="Expiration Time"
                    description="In how many days new Gameflip listings will expire."
                    :alert="alerts.defaultExpiresInDays"
                >
                    <input
                        type="number"
                        min="1"
                        max="30"
                        class="form-control"
                        v-model="defaultExpiresInDays"
                    />
                </setting-group>

                <setting-group
                    class="setting-group"
                    title="Category"
                    description="The Gameflip category to use for new products."
                >
                    <select
                        name="category"
                        class="form-control"
                        v-model="defaultCategory"
                    >
                        <option v-for="(x, i) in productCategories" :key="i">{{
                            x
                        }}</option>
                    </select>
                </setting-group>

                <setting-group
                    class="setting-group"
                    title="UPC"
                    description="Which game new products will be posted under"
                >
                    <select
                        name="upc"
                        class="form-control"
                        v-model="defaultUpc"
                    >
                        <option v-for="(upc, i) in upcInformation" :key="i">{{
                            upc.upcId
                        }}</option>
                    </select>
                </setting-group>

                <setting-group
                    class="setting-group"
                    title="Delivery Method"
                    description="How you will deliver your products."
                >
                    <select
                        name="deliveryMethod"
                        class="form-control"
                        v-model="defaultDeliveryMethod"
                    >
                        <option
                            v-for="(x, i) in productDeliveryMethods"
                            :key="i"
                            >{{ x }}</option
                        >
                    </select>
                </setting-group>

                <setting-group
                    class="setting-group"
                    title="Platform"
                    description="The platform to use for new products."
                >
                    <select
                        name="platform"
                        class="form-control"
                        v-model="defaultPlatform"
                    >
                        <option v-for="(x, i) in productPlatforms" :key="i">{{
                            x
                        }}</option>
                    </select>
                </setting-group>
            </div>
        </div>

        <button
            type="button"
            class="btn btn-primary apply-button"
            @click="applySettings"
        >
            <span v-if="!loading">Apply</span>
            <span
                class="spinner-border spinner-border-sm"
                role="status"
                v-if="loading"
            ></span>
        </button>
    </div>
</template>

<style scoped>
h3 {
    margin-bottom: 2rem;
}

.main-container {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}

.main-container > * {
    margin-top: 1.5rem;
}

.setting-group {
    margin-top: 1.5rem;
    /* border-bottom: 1px solid var(--light); */
    padding-bottom: 1.5rem;
}

.card:hover h3 {
    color: var(--primary);
}

@media (max-width: 55rem) {
    .apply-button {
        width: 100%;
    }
}

@media (min-width: 55rem) {
    .apply-button {
        width: 15rem;
        float: right;
    }
}
</style>

<script>
import SettingGroup from "../components/SettingGroup.vue";
import {
    apiRequest,
    prettyStringSeconds,
    looksLikeValidUsername
} from "../main.js";

export default {
    name: "SettingsView",
    components: {
        SettingGroup
    },
    data() {
        return {
            loading: false,
            generalAlert: "",
            alerts: {},

            // Raw inputs
            // These are used as models for the form inputs
            timeBetweenPosts: 0,
            autoPurgeOption: "",
            autoPurgeHours: "",
            username: "",
            password1: "",
            password2: "",
            defaultShipsInDays: 0,
            defaultExpiresInDays: 0,
            defaultCategory: "",
            defaultUpc: "",
            defaultDeliveryMethod: "",
            defaultPlatform: ""
        };
    },
    computed: {
        productCategories() {
            return this.$store.state.productCategories;
        },
        upcInformation() {
            return this.$store.state.upcInformation;
        },
        productDeliveryMethods() {
            return this.$store.state.productDeliveryMethods;
        },
        productPlatforms() {
            return this.$store.state.productPlatforms;
        },
        minimumTimeBetweenPosts() {
            let subTierName = this.$store.state.profile.subscriptionTier;
            let subTierInfo = this.$store.state.subscriptionTiers[subTierName];
            return subTierInfo.minimumTimeBetweenPosts;
        },
        formIsValid() {
            return Object.keys(this.alerts).length == 0;
        },
        profile() {
            return this.$store.state.profile;
        }
    },
    methods: {
        // Parsers
        // These attempt to parse a field and check the field's validity. If the
        // field is valid the parsed value is returned. If not, a human readable
        // error message is thrown. Lastly, parsers can return `undefined` to
        // indicate that no key should be set.
        parseTimeBetweenPosts() {
            let timeBetweenPosts = parseFloat(this.timeBetweenPosts);
            if (timeBetweenPosts < this.minimumTimeBetweenPosts) {
                throw `The minimum time between posts for your subscription is ${prettyStringSeconds(
                    this.minimumTimeBetweenPosts
                )}. Upgrade your subscription to lower your limit.`;
            }
            return timeBetweenPosts;
        },
        parseAutoPurge() {
            // Fixed strings
            if (
                this.autoPurgeOption == "expired" ||
                this.autoPurgeOption == "off"
            ) {
                return this.autoPurgeOption;
            }

            // Duration, in hours
            let hours = parseInt(this.autoPurgeHours);
            if (hours < 1) {
                throw "This is too short. Set a longer time.";
            }

            return hours;
        },
        parseUsername() {
            const username = this.username.trim();

            // Keep unchanged
            if (username.length == 0) {
                return undefined;
            }

            // Valid
            if (looksLikeValidUsername(username)) {
                return username;
            }

            // Invalid
            throw "Invalid Username";
        },
        parsePassword() {
            const password1 = this.password1.trim();
            const password2 = this.password2.trim();

            // Invalid
            if (password1 != password2) {
                throw "The passwords to not match";
            }

            // Keep unchanged
            if (password1.length == 0) {
                return undefined;
            }

            // Valid
            return password1;
        },
        parseDefaultShipsInDays() {
            // Duration, in days
            let days = Math.round(parseFloat(this.defaultShipsInDays));

            if (days < 1) {
                throw "This is too short. Set a longer time.";
            }

            return days;
        },
        parseDefaultExpiresInDays() {
            // Duration, in days
            let days = Math.round(parseFloat(this.defaultExpiresInDays));

            if (days < 1) {
                throw "This is too short. Set a longer time.";
            }

            return days;
        },
        validateForm() {
            // Parse and validate the fields
            let parsers = {
                timeBetweenPosts: this.parseTimeBetweenPosts,
                autoPurge: this.parseAutoPurge,

                username: this.parseUsername,
                password: this.parsePassword,

                defaultShipsInDays: this.parseDefaultShipsInDays,
                defaultExpiresInDays: this.parseDefaultExpiresInDays
            };

            this.generalAlert = "";
            this.alerts = {};
            let newProfile = {};

            for (var name in parsers) {
                let parser = parsers[name];

                try {
                    let newValue = parser();

                    if (newValue != undefined) {
                        newProfile[name] = newValue;
                    }
                } catch (error_message) {
                    this.alerts[name] = error_message;
                }
            }

            // Some fields require no validation. Copy them over
            newProfile.defaultCategory = this.defaultCategory;
            newProfile.defaultUpc = this.defaultUpc;
            newProfile.defaultDeliveryMethod = this.defaultDeliveryMethod;
            newProfile.defaultPlatform = this.defaultPlatform;

            return newProfile;
        },
        async applySettings() {
            // Client side check - all settings valid?
            let newProfile = this.validateForm();

            if (!this.formIsValid) {
                return;
            }

            // Update the settings server-side
            this.loading = true;

            const response = await apiRequest(
                "POST",
                "/api/me/profile",
                newProfile
            );

            this.loading = false;

            // Success
            if (response.success) {
                this.generalAlert = "";
                this.alerts = {};

                // Notify the user
                this.$store.commit("addNotification", {
                    urgency: "success",
                    message: "Your settings have been updated",
                    timeout: 8
                });

                // Fetch the new user settings
                this.$store.dispatch("fetchProfile");

                // Navigate back to the main app
                this.$router.push({ name: "app" });
                return;
            }

            // Error message not related to any particular key
            this.generalAlert = response.message;

            // Errors that are related to keys
            if (response.offendingKeys != undefined) {
                this.alerts = response.offendingKeys;
            } else {
                this.alerts = {};
            }
        },
        copyFieldsFromProfile() {
            // Set the initial values for the fields
            const profile = this.$store.state.profile;

            if (profile.autoPurge == "expired" || profile.autoPurge == "off") {
                this.autoPurgeOption = profile.autoPurge;
            } else {
                this.autoPurgeOption = "older than hours...";
                this.autoPurgeHours = profile.autoPurge;
            }

            this.timeBetweenPosts = profile.timeBetweenPosts;
            this.defaultShipsInDays = profile.defaultShipsInDays;
            this.defaultExpiresInDays = profile.defaultExpiresInDays;
            this.defaultCategory = profile.defaultCategory;
            this.defaultUpc = profile.defaultUpc;
            this.defaultDeliveryMethod = profile.defaultDeliveryMethod;
            this.defaultPlatform = profile.defaultPlatform;
        },
        async findOptimalSettings() {
            this.loading = true;

            await apiRequest("POST", "/api/me/optimizePostSettings", null);
            await this.$store.dispatch("fetchProfile");

            this.loading = false;
        }
    },
    mounted() {
        this.copyFieldsFromProfile();
    },
    watch: {
        profile: "copyFieldsFromProfile"
    }
};
</script>
