<template>
    <div id="postCustomBundleModal" class="modal" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Post Custom Bundle</h5>
                </div>
                <div v-if="!completed">
                    <div class="modal-body">
                        <div>
                            <div
                                class="alert alert-danger"
                                role="alert"
                                v-if="alertText.length != 0"
                            >
                                {{ alertText }}
                            </div>

                            <div class="text-center mb-4">
                                Creates a custom listing without saving the
                                product.
                            </div>

                            <product-editor
                                :essentialsOnly="true"
                                ref="productEditor"
                            ></product-editor>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- Dismissing the modal without JavaScript causes odd Behavrior. Do it programmatically -->
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="dismissDialog"
                            :disabled="loading"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="createListing"
                            :disabled="loading"
                        >
                            Post Listing
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                v-if="loading"
                            ></span>
                        </button>
                    </div>
                </div>
                <div v-if="completed">
                    <div class="modal-body">
                        <b class="text-success"
                            >Your listing has been posted!</b
                        >
                        <br />
                        <a
                            class="text-success"
                            :href="listingUrl"
                            target="_blank"
                            rel="noopener noreferrer"
                            >{{ listingUrl }}</a
                        >
                    </div>
                    <div class="modal-footer">
                        <!-- Dismissing the modal without JavaScript causes odd Behavrior. Do it programmatically -->
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="reset()"
                        >
                            Post another
                        </button>
                        <button
                            type="button"
                            class="btn btn-success"
                            @click="dismissDialog"
                        >
                            Sounds good!
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import ProductEditor from "./ProductEditor.vue";
import { apiRequest } from "../main.js";

export default {
    name: "PostCustomBundleDialog",
    components: {
        ProductEditor
    },
    data: function() {
        return {
            alertText: "",
            completed: false,
            loading: false,
            listingUrl: ""
        };
    },
    computed: {
        productCategories() {
            return this.$store.state.productCategories;
        },
        upcInformation() {
            return this.$store.state.upcInformation;
        },
        productDeliveryMethods() {
            return this.$store.state.productDeliveryMethods;
        },
        productPlatforms() {
            return this.$store.state.productPlatforms;
        }
    },
    methods: {
        reset() {
            this.alertText = "";
            this.completed = false;

            // The product editor might not exist, since it's not displayed by
            // the `v-if` right now. Update the product on next tick.
            const this_ = this;
            this.$nextTick(function() {
                this_.$refs.productEditor.updateProduct(
                    {
                        productId: "special:customBundle",
                        name: "Custom Order",
                        description:
                            "This is a custom order.\nOnly buy this if you have talked to me first.",
                        priceInCents: 1000
                    },
                    true
                );
            });
        },
        async createListing() {
            // Reset state
            this.loading = true;

            const editor = this.$refs.productEditor;
            const product = editor.getProduct();

            // Update the image if the user has changed it
            if (editor.userHasChangedProductImage) {
                await editor.postProductImage();
            }

            // Post
            const response = await apiRequest(
                "POST",
                "/api/action/postCustomListing",
                {
                    name: product.name,
                    description: product.description,
                    priceInCents: product.priceInCents
                }
            );

            this.loading = false;

            // Failure
            if (!response.success) {
                return;
            }

            // Provide feedback to the user
            this.completed = true;
            this.listingUrl = "https://gameflip.com/item/".concat(response.id);
        },
        dismissDialog() {
            $("#postCustomBundleModal").modal("hide");
        }
    }
};
</script>
