<template>
    <div class="toplevel-fortnite-view">
        <!-- Warn if not supported by the subscription -->

        <div
            class="alert alert-warning"
            role="alert"
            v-if="!userSubscriptionInfo['allowFortniteApi']"
        >
            <router-link :to="{ name: 'subscription' }">
                Upgrade your subscription
            </router-link>
            to get access to the Fortnite API.
        </div>

        <!-- Account Linking and Selection -->

        <h3>Epic Account</h3>

        <div class="card">
            <div class="card-body link-epic-box">
                <div class="text-left mb-n3">
                    Select your Epic account:
                </div>

                <div
                    class="alert alert-danger"
                    role="alert"
                    v-if="linkAlertText.length != 0"
                >
                    {{ linkAlertText }}
                </div>

                <select
                    name="selectedAccount"
                    class="form-control mt-4"
                    v-model="currentAccount"
                    @change="accountChange"
                >
                    <option :value="null">Link New Account</option>
                    <option
                        v-for="acc in $store.state.linkedEpicAccounts"
                        :key="acc.epicAccountId"
                        :value="acc"
                    >
                        {{ acc.displayName }}
                    </option>
                </select>

                <div v-if="currentAccount === null" class="mt-4">
                    <div>
                        <a
                            class="text-link"
                            href="http://epicgames.com/id/logout?lang=en-US&redirectUrl=https%3A%2F%2Fwww.epicgames.com%2Fid%2Flogin%3FredirectUrl%3Dhttps%253A%252F%252Fwww.epicgames.com%252Fid%252Fapi%252Fredirect%253FclientId%253D3446cd72694c4a4485d81b77adbb2141%2526responseType%253Dcode"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Press here</a
                        >
                        to log into your Epic Games account.
                    </div>

                    <img
                        src="/link-epic-images/copy-auth-code.png"
                        class="guide-image mt-3"
                    />

                    <div class="mt-3">
                        You will see code, like in the picture. Copy and Paste
                        <b>all of it</b> into the box below.
                    </div>

                    <form class="simple-form">
                        <input
                            type="text"
                            class="form-control"
                            v-model="authCodeJson"
                        />
                        <button
                            type="button"
                            class="btn btn-primary link-account-button"
                            @click="linkAccountClick"
                            :disabled="
                                loading || authCodeJson.trim().length == 0
                            "
                        >
                            <span v-if="!loading">
                                Link Account
                            </span>
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                v-if="loading"
                            ></span>
                        </button>
                    </form>
                </div>
            </div>
        </div>

        <!-- Alert for actions -->

        <div
            class="alert"
            :class="actionAlertClass"
            role="alert"
            v-if="actionsAlertText.length != 0"
        >
            {{ actionsAlertText }}
        </div>

        <!-- Action selection -->

        <div
            class="action-selection-box"
            v-if="currentAccount !== null && selectedAction === 'none'"
        >
            <button
                type="button"
                class="btn btn-danger"
                @click="unlinkAccountClick"
            >
                Unlink this Account
                <span class="rate-limit-label">
                    ∞
                </span>
            </button>

            <button
                type="button"
                class="btn btn-secondary"
                @click="collectDailyRewardsClick"
            >
                Collect Daily Rewards
                <span class="rate-limit-label">
                    ∞
                </span>
            </button>

            <button
                type="button"
                class="btn btn-secondary"
                @click="claimFreeLlamasClick"
            >
                Collect Free Llamas
                <span class="rate-limit-label">
                    ∞
                </span>
            </button>

            <button
                type="button"
                class="btn btn-secondary"
                @click="selectedAction = 'rename-homebase'"
                v-if="
                    userSubscriptionInfo['fortniteRateLimitRenameHomebase'] > 0
                "
            >
                Rename Homebase
                <span class="rate-limit-label">
                    {{
                        userSubscriptionInfo["fortniteRateLimitRenameHomebase"]
                    }}/day
                </span>
            </button>

            <button
                type="button"
                class="btn btn-secondary"
                @click="launchFortniteClick"
            >
                Launch Fortnite (PC)
                <span class="rate-limit-label">
                    ∞
                </span>
            </button>

            <button
                type="button"
                class="btn btn-secondary"
                @click="
                    $router.push({
                        name: 'fortnite-inventory',
                        query: { accountId: currentAccount.epicAccountId }
                    })
                "
            >
                Show Inventory
                <span class="rate-limit-label">
                    ∞
                </span>
            </button>
        </div>

        <div v-if="currentAccount === null">
            <h3 class="mt-4">Select an account or link a new one.</h3>
            You can rename your homebase, collect free rewards &amp; more!
        </div>

        <button
            type="button"
            class="btn btn-secondary"
            @click="
                $router.push({
                    name: 'fortnite-inventory'
                })
            "
            v-if="currentAccount === null"
        >
            Show Inventory of all linked Accounts
        </button>

        <!-- Action-Specific Views -->

        <div class="card" v-if="selectedAction == 'rename-homebase'">
            <div class="card-body">
                <div v-if="selectedAction == 'rename-homebase'">
                    <h4>Rename Homebase</h4>
                    <div class="text-left">
                        Make sure you are not in a mission.
                    </div>
                    <form class="simple-form">
                        <input
                            type="text"
                            class="form-control"
                            v-model="homebaseName"
                            placeholder="Homebase Name"
                        />
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="renameHomebaseClick"
                            :disabled="
                                loading || homebaseName.trim().length == 0
                            "
                        >
                            <span v-if="!loading">Rename</span>
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                v-if="loading"
                            ></span>
                        </button>
                    </form>
                </div>
            </div>
        </div>

        <div class="card" v-if="selectedAction == 'launch-fortnite'">
            <div class="card-body">
                <h4>Launch Fortnite (PC Only)</h4>
                <div class="text-left">
                    Run this text in the Windows Command Line:
                </div>
                <div
                    v-if="launchCodeLoading"
                    class="spinner-border spinner-border-sm"
                ></div>
                <div class="text-left code-box" v-else>
                    start "" "C:\Program Files\Epic
                    Games\Fortnite\FortniteGame\Binaries\Win64\FortniteLauncher.exe"
                    -AUTH_LOGIN=unused -AUTH_PASSWORD={{ launchCode }}
                    -AUTH_TYPE=exchangecode -epicapp=Fortnite -epicenv=Prod
                    -epicportal -epiclocale=en-us
                </div>
            </div>
        </div>

        <!-- Common Controls -->

        <button
            type="button"
            class="btn btn-danger back-button"
            v-if="currentAccount !== null && selectedAction !== 'none'"
            @click="selectedAction = 'none'"
        >
            Back
        </button>
    </div>
</template>

<style scoped>
.toplevel-fortnite-view {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}

.guide-image {
    max-width: 100%;
    max-height: 9rem;
}

.toplevel-fortnite-view > * {
    margin-bottom: 1rem;
}

.link-epic-box > * {
    margin-bottom: 0.6rem;
}

.rate-limit-label {
    width: 5rem;
    float: right;
    text-align: right;
}

.code-box {
    background: black;
    padding: 0.5rem;
    border-radius: 0.2rem;
}

@media (max-width: 50rem) {
    .action-selection-box > * {
        width: 100%;
        margin-bottom: 0.8rem;
    }

    .simple-form {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }

    .simple-form button {
        margin-top: 0.6rem;
    }

    .simple-button-bar {
        margin-top: 1rem;
        width: 100%;
    }

    .back-button {
        width: 100%;
    }
}

@media (min-width: 50rem) {
    .link-epic-box .form-control {
        margin-left: auto;
        margin-right: auto;
    }

    .link-account-button {
        width: 12rem;
    }

    .action-selection-box {
        width: 20rem;
        margin-left: auto;
        margin-right: auto;
    }

    .action-selection-box > * {
        width: 100%;
        margin-bottom: 0.8rem;
    }

    .simple-form {
        display: flex;
        margin: 1rem auto 0 auto;
    }

    .simple-form button {
        width: 13rem;
        margin-left: 0.8rem;
    }

    .simple-button-bar {
        margin-top: 1rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .simple-button-bar button {
        width: 15rem;
    }

    .back-button {
        float: right;
        width: 15rem;
    }
}
</style>

<script>
import { apiRequest } from "../main.js";

export default {
    name: "FortniteView",
    data() {
        return {
            loading: false,

            currentAccount: null,

            authCodeJson: "",
            linkAlertText: "",

            actionsAlertType: "",
            actionsAlertText: "",

            // Valid actions:
            //   "none"
            //   "rename-homebase"
            selectedAction: "",

            homebaseName: "",

            launchCode: "",
            launchCodeLoading: false,

            allSchematics: [],
            selectedSchematicTemplateId: "",
            selectedSchematic: null,

            allItems: [],
            selectedItemTemplateId: "",
            selectedItem: null
        };
    },
    computed: {
        userSubscriptionInfo() {
            return this.$store.state.subscriptionTiers[
                this.$store.state.profile.subscriptionTier
            ];
        },
        authCode() {
            // Attempt to extract the code
            {
                let match = /code=([a-f0-9]+)/.exec(this.authCodeJson);

                if (match != null) {
                    return match[1];
                }
            }

            // Is the auth code already extracted?
            {
                let trimmed = this.authCodeJson.trim();
                if (/[a-f0-9]+/.test(trimmed)) {
                    return trimmed;
                }
            }

            return null;
        },
        actionAlertClass() {
            return `alert-${this.actionsAlertType}`;
        }
    },
    methods: {
        async linkAccountClick() {
            // Reset state
            this.linkAlertText = "";

            // Valid looking auth code?
            if (this.authCode == null) {
                this.linkAlertText =
                    "That does not look like a valid auth code. Copy as much as possible if you are not sure which part to copy.";
                return;
            }

            // Try to link
            this.loading = true;

            const response = await apiRequest(
                "POST",
                "/api/action/linkEpicAccount",
                {
                    authCode: this.authCode
                }
            );

            if (response.success) {
                this.linkAlertText = "";
                await this.$store.dispatch("fetchLinkedEpicAccounts");
                this.setCurrentAccountById(response.epicAccountId);
                return;
            }

            this.loading = false;
        },
        async unlinkAccountClick() {
            // Try to link
            this.loading = true;

            const response = await apiRequest(
                "POST",
                "/api/action/unlinkEpicAccount",
                {
                    epicAccountId: this.currentAccount.epicAccountId
                }
            );

            if (response.success) {
                this.linkAlertText = "";
                await this.$store.dispatch("fetchLinkedEpicAccounts");
            } else {
                this.linkAlertText = response.message;
            }

            this.loading = false;
            this.currentAccount = null;
        },
        async collectDailyRewardsClick() {
            this.actionsAlertText = "";
            this.loading = true;

            let response = await apiRequest(
                "POST",
                "/api/fortnite/collectDailyRewards",
                {
                    epicAccountId: this.currentAccount.epicAccountId
                }
            );

            this.loading = false;

            if (response.success) {
                this.actionsAlertType = "success";
                this.actionsAlertText =
                    "Done! Your login rewards have been claimed.";
            } else {
                this.actionsAlertType = "danger";
                this.actionsAlertText = response.message;
            }
        },
        async claimFreeLlamasClick() {
            this.actionsAlertText = "";
            this.loading = true;

            let response = await apiRequest(
                "POST",
                "/api/fortnite/claimFreeLlamas",
                {
                    epicAccountId: this.currentAccount.epicAccountId
                }
            );

            this.loading = false;

            if (response.success) {
                if (response.nClaimed == 0) {
                    this.actionsAlertType = "info";
                    this.actionsAlertText =
                        "There are no free llamas available right now.";
                } else {
                    this.actionsAlertType = "success";
                    this.actionsAlertText = `Done! Claimed ${response.nClaimed} llama(s).`;
                }
            } else {
                this.actionsAlertType = "danger";
                this.actionsAlertText = response.message;
            }
        },
        async renameHomebaseClick() {
            this.loading = true;

            const response = await apiRequest(
                "POST",
                "/api/fortnite/renameHomebase",
                {
                    epicAccountId: this.currentAccount.epicAccountId,
                    newHomebaseName: this.homebaseName.trim()
                }
            );

            if (response.success) {
                this.actionsAlertType = "success";
                this.actionsAlertText = "Done! Your homebase has been renamed.";
                this.selectedAction = "none";
            } else {
                this.actionsAlertType = "danger";
                this.actionsAlertText = response.message;
            }

            this.loading = false;
        },
        async launchFortniteClick() {
            this.selectedAction = "launch-fortnite";
            this.launchCodeLoading = true;

            const response = await apiRequest(
                "GET",
                `/api/fortnite/${this.currentAccount.epicAccountId}/launchCode`
            );

            this.launchCode = response.code;
            this.launchCodeLoading = false;
        },
        async setCurrentAccountById(accountId) {
            const accounts = this.$store.state.linkedEpicAccounts;

            for (var ii = 0; ii < accounts.length; ++ii) {
                const account = accounts[ii];

                if (account.epicAccountId === accountId) {
                    this.currentAccount = account;
                    await this.accountChange();
                    return;
                }
            }

            console.assert(false, "Invalid account ID");
        },
        async accountChange() {
            if (this.currentAccount === null) {
                return;
            }

            this.loading = true;
            var success = true;

            this.loading = false;

            // Update state
            if (success) {
                this.linkAlertText = "";
                this.selectedAction = "none";
            } else {
                this.linkAlertText = `Could not access ${this.currentAccount.displayName}. Link it again.`;
                this.currentAccount = null;
            }
        }
    }
};
</script>
