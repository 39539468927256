<template>
    <div class="card">
        <div class="card-body" id="card-grid">
            <div id="card-header">
                <b>{{ headerText }}</b>
            </div>
            <div id="card-description">
                {{ descriptionText }}
            </div>
            <div id="card-counters" v-if="isRunning">
                <div class="card-counter">
                    <b>Purged</b>
                    <br />
                    {{ job.nPurged }}
                </div>
                <div class="card-counter">
                    <b>Total</b>
                    <br />
                    {{ job.nFound }} +
                </div>
            </div>

            <button
                type="button"
                class="btn btn-secondary job-control-button"
                v-if="!isRunning"
                @click="startPurgeExpiredJob"
                :disabled="loading"
            >
                <span v-if="!loading">Remove Expired</span>
                <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="loading"
                ></span>
            </button>

            <!-- <button
                type="button"
                class="btn btn-secondary job-control-button"
                v-if="!isRunning"
                @click="startPurgeDraftJob"
                :disabled="loading"
            >
                <span v-if="!loading">Remove Drafts</span>
                <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="loading"
                ></span>
            </button> -->

            <button
                type="button"
                class="btn btn-secondary job-control-button"
                v-if="!isRunning"
                @click="startPurgeAllJob"
                :disabled="loading"
            >
                <span v-if="!loading">Remove All</span>
                <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="loading"
                ></span>
            </button>

            <button
                type="button"
                class="btn btn-secondary job-control-button"
                v-if="!isRunning"
                @click="startPurgeOldJob"
                :disabled="loading"
            >
                <span v-if="!loading">
                    Remove older than
                    <input
                        type="number"
                        min="1"
                        max="30"
                        class="form-control"
                        id="purgeDurationBox"
                        v-model="purgeOlderThanDays"
                        @click="purgeDurationBoxClick"
                    />
                    day(s)
                </span>
                <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="loading"
                ></span>
            </button>

            <button
                type="button"
                class="btn btn-danger job-control-button"
                v-if="isRunning"
                @click="stopJob"
                :disabled="loading"
            >
                <span v-if="!loading">Stop Purging</span>
                <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="loading"
                ></span>
            </button>
        </div>
    </div>
</template>

<style scoped>
#card-header {
    margin-bottom: 1rem;
}

#card-description {
    margin-bottom: 1rem;
}

#card-counters {
    display: flex;
    margin-bottom: 1rem;
}

#card-counters.space-around {
    justify-content: space-around;
}

#card-counters.space-between {
    justify-content: space-between;
}

.card-counter {
    width: 50%;
}

.job-control-button {
    margin-top: 0.2rem;
    display: block;
    width: 100%;
}

#purgeDurationBox {
    /* Remove the spin buttons */
    -webkit-appearance: none;
    -moz-appearance: textfield;

    padding: 0.1rem;
    width: 3rem;
    height: 1.9rem;
    display: inline;
    color: white;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    border-width: 0;
}
</style>

<script>
import { apiRequest, prettyStringSeconds } from "../main.js";

export default {
    name: "PurgeJobCard",
    data() {
        return {
            loading: false,
            purgeOlderThanDays: 3
        };
    },
    computed: {
        job() {
            return this.$store.state.purgeJob;
        },
        isRunning() {
            return this.job != null;
        },
        headerText() {
            if (!this.isRunning) {
                return "Purge Listings Now";
            }

            if (this.job.selector == "all ") {
                return "Purging all listings";
            }

            if (this.job.selector == "draft") {
                return "Purging drafts";
            }

            if (this.job.selector == "expired") {
                return "Purging expired listings";
            }

            return "Purging old listings";
        },
        descriptionText() {
            if (!this.isRunning) {
                return "";
            }

            if (this.job.selector == "all") {
                return "Removing all listings that are on sale or expired.";
            }

            if (this.job.selector == "draft") {
                return "Removing all drafts.";
            }

            if (this.job.selector == "expired") {
                return "Removing all expired listings.";
            }

            return `Removing all listings older than ${prettyStringSeconds(
                this.job.selector * 60 * 60,
                false
            )}.`;
        }
    },
    methods: {
        async startPurgeExpiredJob() {
            this.loading = true;
            await apiRequest("POST", "/api/action/startPurgeJob", {
                selector: "expired"
            });
            await this.$store.dispatch("fetchJobs");
            this.loading = false;
        },
        async startPurgeDraftJob() {
            this.loading = true;
            await apiRequest("POST", "/api/action/startPurgeJob", {
                selector: "draft"
            });
            await this.$store.dispatch("fetchJobs");
            this.loading = false;
        },
        async startPurgeAllJob() {
            this.loading = true;
            await apiRequest("POST", "/api/action/startPurgeJob", {
                selector: "all"
            });
            await this.$store.dispatch("fetchJobs");
            this.loading = false;
        },
        async startPurgeOldJob() {
            this.loading = true;
            await apiRequest("POST", "/api/action/startPurgeJob", {
                selector: parseFloat(this.purgeOlderThanDays) * 24
            });
            await this.$store.dispatch("fetchJobs");
            this.loading = false;
        },
        async stopJob() {
            this.loading = true;
            await apiRequest("POST", "/api/action/stopJob", {
                jobId: this.$store.state.purgeJob.jobId
            });
            await this.$store.dispatch("fetchJobs");
            this.loading = false;
        },
        purgeDurationBoxClick(e) {
            e.stopPropagation();
        }
    }
};
</script>
