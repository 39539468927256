<template>
    <div class="subscription-view-toplevel">
        <h1>Subscriptions</h1>

        <div class="card mb-4">
            <div class="card-body">
                <!-- Valid Sub -->
                <div v-if="subscriptionIsValid">
                    🎊 Your
                    <b>{{ currentSubscriptionTierInfo.nicename }}</b>
                    subscription is <b>{{ validSubscriptionShortText }}</b> 🎊
                </div>

                <!-- Invalid Sub -->
                <div v-if="!subscriptionIsValid">
                    <div>Your subscription <b>has expired</b>.</div>

                    <a
                        href="https://gameflip.com/profile/us-east-1:cac20619-48a7-4ebc-aaf2-6985b553b794"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <button
                            type="button"
                            class="btn btn-success buy-button"
                        >
                            Buy a Subscription
                        </button>
                    </a>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <div class="tiersBox">
                    <subscription-tier-box
                        class="tierBox"
                        v-for="tierInfo in advertisedTiers"
                        :key="tierInfo.slug"
                        :tierInfo="tierInfo"
                    ></subscription-tier-box>
                </div>
            </div>
        </div>

        <h1>Redeem Code</h1>

        <div class="card">
            <div class="card-body">
                <div
                    class="alert alert-success"
                    role="alert"
                    v-if="redeemSuccessText.length != 0"
                >
                    {{ redeemSuccessText }}
                </div>
                <div
                    class="alert alert-danger"
                    role="alert"
                    v-if="redeemErrorText.length != 0"
                >
                    {{ redeemErrorText }}
                </div>

                Buy a subscription from
                <a
                    href="https://gameflip.com/profile/us-east-1:cac20619-48a7-4ebc-aaf2-6985b553b794"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Gameflip</a
                >, then enter the code here.

                <form class="redemption-form">
                    <label for="keyCode" class="form-elem d-none"
                        >Key Code</label
                    >
                    <input
                        type="text"
                        id="keyCode"
                        class="form-elem form-control"
                        v-model="keyCode"
                        placeholder="Key"
                    />
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="redeemClick"
                    >
                        Redeem
                    </button>
                </form>
            </div>
        </div>

        <h1>Discounts</h1>

        <div class="mb-3">
            <b>Share AutoFlipper with other sellers to unlock a discount!</b>
            <br />
            Every friend with a valid subscription makes yours cheaper.
        </div>

        <div class="mb-4"><b>Each time</b> you buy 30 days, you get</div>

        <!-- <div class="card discount-card">
            <div class="card-body">✅ <b>30 days</b> Base</div>
        </div> -->

        <div
            class="card discount-card"
            v-for="friend in validFriends"
            :key="friend.nicename"
        >
            <div class="card-body">
                ✅ <b>+ 10 days free</b>
                <br />
                for sharing with
                {{ friend.nicename }}
            </div>
        </div>

        <div class="card discount-card">
            <div class="card-body">
                🔒
                <b>+ 10 days free</b>
                <br />
                <i>Share with one more person to unlock this discount</i>
            </div>
        </div>

        <div class="card discount-card">
            <div class="card-body">
                🔒
                <b>+ 10 days free</b>
                <br />
                <i>Share with two more people to unlock this discount</i>
            </div>
        </div>

        <div class="card discount-card">
            <div class="card-body">
                🔒
                <b>+ 10 days free</b>
                <br />
                <i>Share with three more people to unlock this discount</i>
            </div>
        </div>
    </div>
</template>

<style scoped>
.subscription-view-toplevel {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
}

h1 {
    margin-top: 4rem;
    margin-bottom: 2rem;
}

.tiersBox {
    display: flex;
}

.tierBox {
    margin: 0.5rem;
    padding: 0.6rem;
    flex: 1 1 0px;
}

.discount-card {
    margin-bottom: 1rem;
}

@media (max-width: 50rem) {
    .buy-button {
        margin-top: 1rem;
        width: 100%;
    }

    .tiersBox {
        flex-direction: column;
    }

    .redemption-form {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }

    .redemption-form button {
        margin-top: 0.6rem;
    }
}

@media (min-width: 50rem) {
    .buy-button {
        margin-top: 1rem;
        width: 10rem;
    }

    .redemption-form {
        display: flex;
        max-width: 40rem;
        margin: 1rem auto 0 auto;
    }

    .redemption-form button {
        margin-left: 0.8rem;
    }
}
</style>

<script>
import { apiRequest } from "../main";
import SubscriptionTierBox from "../components/SubscriptionTierBox.vue";

export default {
    components: { SubscriptionTierBox },
    name: "SubscriptionView",
    data() {
        return {
            redeemSuccessText: "",
            redeemErrorText: "",
            keyCode: "",
            validFriends: [],
            invalidFriends: []
        };
    },
    computed: {
        tiers() {
            return this.$store.state.subscriptionTiers;
        },
        currentSubscriptionTierInfo() {
            return this.$store.state.subscriptionTiers[
                this.$store.state.profile.subscriptionTier
            ];
        },
        currentSubscriptionValidity() {
            return this.$store.state.profile.subscriptionValidity;
        },
        subscriptionIsValid() {
            return (
                this.currentSubscriptionValidity == true ||
                this.currentSubscriptionValidity > Date.now() / 1000
            );
        },
        validSubscriptionShortText() {
            console.assert(this.subscriptionIsValid);

            if (this.currentSubscriptionValidity == true) {
                return "indefinite";
            } else {
                let validityDate = new Date(
                    this.currentSubscriptionValidity * 1000
                );
                return `valid until ${validityDate.toLocaleString()}`;
            }
        },
        advertisedTiers() {
            return Object.values(this.$store.state.subscriptionTiers).filter(
                tierInfo => tierInfo.advertiseForPurchase
            );
        }
    },
    methods: {
        async redeemClick() {
            // Reset state
            this.redeemSuccessText = "";
            this.redeemErrorText = "";

            // Login
            const response = await this.$store.dispatch(
                "redeemKey",
                this.keyCode.trim()
            );

            if (response.success) {
                this.redeemSuccessText = response.message;
                this.keyCode = "";
            } else {
                this.redeemErrorText = response.message;
            }
        }
    },
    async mounted() {
        const response = await apiRequest("GET", "/api/me/discounts");

        this.validFriends = response.validFriends;
        this.invalidFriends = response.invalidFriends;
    }
};
</script>
