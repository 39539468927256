<template>
    <wizard
        heading="Link your Gameflip Account"
        initialPageName="log-in"
        class="toplevel"
        :pageInfos="pageInfos"
        ref="wizard"
    >
        <!-- Log In -->
        <template v-slot:log-in>
            <div class="mb-2">Log into the Gameflip website.</div>
            <div>
                <a
                    href="https://gameflip.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    >gameflip.com</a
                >
            </div>
            <div class="mt-3">
                (Do not use the app for linking accounts. It does not support
                setting up your API.)
            </div>
        </template>

        <!-- Go to Settings -->
        <template v-slot:go-to-settings>
            <img
                src="/link-gameflip-images/1-go-to-settings.png"
                class="guide-image"
            />
            <div class="mb-2">
                Press on your profile picture, then <b>Settings</b>
            </div>
        </template>

        <!-- Go to Develop -->
        <template v-slot:go-to-develop>
            <img
                src="/link-gameflip-images/2-go-to-develop.png"
                class="guide-image"
            />
            <div class="mb-2">
                On the top of the page, press on <b>Develop</b>
            </div>
        </template>

        <!-- Go click the "Create API Key" Button -->
        <template v-slot:click-create-api-key-button>
            <img
                src="/link-gameflip-images/3-click-create-api-key-button.png"
                class="guide-image"
            />
            <div class="mb-2">
                You will see a button named "Create API Key". Press it.
            </div>
        </template>

        <!-- Copy the API Secret -->
        <template v-slot:copy-secret>
            <img
                src="/link-gameflip-images/4-copy-secret.png"
                class="guide-image"
            />
            <div class="mb-2">
                You will see a message with a code pop up. Copy that code into
                the field below.
            </div>
            <form>
                <div
                    class="alert alert-danger"
                    role="alert"
                    v-if="alertText.length != 0"
                >
                    {{ alertText }}
                </div>
                <label for="apiSecret" class="form-elem d-none"
                    >API Secret</label
                >
                <input
                    type="text"
                    id="apiSecret"
                    class="form-elem form-control"
                    v-model="apiSecret"
                    placeholder="API Secret"
                    @input="apiSecretChange"
                />
            </form>
        </template>

        <!-- Copy the API Key -->
        <template v-slot:copy-key>
            <img
                src="/link-gameflip-images/5-copy-key.png"
                class="guide-image"
            />
            <div class="mb-2">
                There is a second code visible now. Copy that one as well.
            </div>
            <form>
                <div
                    class="alert alert-danger"
                    role="alert"
                    v-if="alertText.length != 0"
                >
                    {{ alertText }}
                </div>
                <label for="apiKey" class="form-elem d-none">API Key</label>
                <input
                    type="text"
                    id="apiKey"
                    class="form-elem form-control"
                    v-model="apiKey"
                    placeholder="API Key"
                    @input="apiKeyChange"
                />
            </form>
        </template>

        <!-- Done - Check the Key/Secret -->
        <template v-slot:checking>
            <div>
                Checking your API access...
            </div>
            <div
                class="spinner-border spinner-border-md mt-2"
                role="status"
            ></div>
        </template>

        <!-- The Key/Secret didn't work -->
        <template v-slot:error>
            <img src="/icons/x-circle.svg" class="status-icon" />
            Your account couldn't be linked. Make sure to copy the API Key and
            Secret correctly.
        </template>

        <!-- The Key/Secret work -->
        <template v-slot:success>
            <h2>Hello, {{ gameflipName }}!</h2>
            <img src="/icons/check.svg" class="status-icon" />
            Your gameflip account is now linked!
        </template>
    </wizard>
</template>

<style scoped>
.toplevel {
    max-width: 40rem;
}

.guide-image {
    width: 100%;
    margin-bottom: 2rem;
}

.status-icon {
    display: block;
    width: 10rem;
    margin: 2rem auto 2rem auto;
}
</style>

<script>
import Wizard from "../components/Wizard.vue";
import { apiRequest } from "../main";

export default {
    name: "LinkGameflipView",
    components: {
        Wizard
    },
    data: function() {
        return {
            alertText: "",
            apiKey: "",
            apiSecret: "",
            gameflipName: "",
            pageInfos: {
                "log-in": {
                    nextButtonName: "I'm logged in",
                    action: "go-to-settings"
                },
                "go-to-settings": {
                    action: "go-to-develop"
                },
                "go-to-develop": {
                    action: "click-create-api-key-button"
                },
                "click-create-api-key-button": {
                    action: "copy-secret"
                },
                "copy-secret": {
                    action: "copy-key",
                    nextButtonEnabled: false
                },
                "copy-key": {
                    action: this.checkSuccess,
                    nextButtonEnabled: false
                },
                checking: {
                    action: "success",
                    nextButtonEnabled: false
                },
                error: {
                    nextButtonName: "Try Again",
                    action: this.retryClick
                },
                success: {
                    nextButtonName: "Nice!",
                    action: this.confirmSuccessClick
                }
            }
        };
    },
    methods: {
        apiKeyChange() {
            const isEntered = this.apiKey.trim() != "";
            this.$refs.wizard.setNextButtonEnabled(isEntered);
        },
        apiSecretChange() {
            const isEntered = this.apiSecret.trim() != "";
            this.$refs.wizard.setNextButtonEnabled(isEntered);
        },
        async checkSuccess() {
            this.$refs.wizard.pushPage("checking");

            console.log(this.$route.query);

            const response = await apiRequest(
                "POST",
                "/api/action/linkGameflip",
                {
                    apiKey: this.apiKey.trim(),
                    apiSecret: this.apiSecret.trim(),
                    setupUser: this.$route.query.setupUser == true
                }
            );

            await this.$store.dispatch("fetchAllGlobals");

            if (response.success) {
                this.gameflipName = response.gameflipName;
                this.$refs.wizard.pushPage("success");
            } else {
                this.$refs.wizard.pushPage("error");
            }
        },
        retryClick() {
            this.$refs.wizard.returnToPage("log-in");
        },
        confirmSuccessClick() {
            this.$router.push({ name: "app" });
        }
    }
};
</script>
