<template>
    <div class="card">
        <div class="card-body" id="card-grid">
            <div id="card-header">
                <b>{{ headerText }}</b>
            </div>
            <div id="card-description" v-if="jobErrorMessage === null">
                {{ descriptionText }}
            </div>
            <div
                class="alert alert-danger"
                role="alert"
                v-if="jobErrorMessage !== null"
            >
                {{ jobErrorMessage }}
            </div>
            <div v-if="isRunning" id="card-counters">
                <div class="card-counter">
                    <b>Posted</b>
                    <br />
                    {{ job.nPosted }}
                </div>
                <div class="card-counter">
                    <b>Purged</b>
                    <br />
                    {{ job.nPurged }}
                </div>
            </div>

            <button
                v-if="!isRunning"
                @click="startJob"
                :disabled="loading"
                type="button"
                class="btn btn-primary job-control-button"
            >
                <span v-if="!loading">Auto-post listings</span>
                <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="loading"
                ></span>
            </button>

            <button
                v-if="isRunning"
                @click="stopJob"
                :disabled="loading"
                type="button"
                class="btn btn-danger job-control-button"
            >
                <span v-if="!loading">Stop Posting</span>
                <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="loading"
                ></span>
            </button>
        </div>
    </div>
</template>

<style scoped>
#card-header {
    margin-bottom: 1rem;
}

#card-description {
    margin-bottom: 1rem;
}

#card-counters {
    display: flex;
    margin-bottom: 1rem;
}

#card-counters.space-around {
    justify-content: space-around;
}

#card-counters.space-between {
    justify-content: space-between;
}

.card-counter {
    width: 50%;
}

.job-control-button {
    margin-top: 0.2rem;
    display: block;
    width: 100%;
}
</style>

<script>
import { apiRequest, prettyStringSeconds } from "../main.js";

export default {
    name: "PostJobCard",
    data() {
        return {
            loading: false
        };
    },
    computed: {
        job() {
            return this.$store.state.postJob;
        },
        isRunning() {
            return this.job != null;
        },
        headerText() {
            if (this.isRunning) {
                return "Posting Listings";
            } else {
                return "Auto-post Listings";
            }
        },
        jobErrorMessage() {
            if (
                this.job === null ||
                this.job.nConsecutiveErrors < 3 ||
                this.job.log.length === 0
            ) {
                return null;
            }

            return this.job.log[this.job.log.length - 1]
        },
        descriptionText() {
            const profile = this.$store.state.profile;

            const timeString = prettyStringSeconds(
                profile.timeBetweenPosts,
                true
            );

            var autoPurgeString;
            if (profile.autoPurge === "off") {
                autoPurgeString = "";
            } else if (profile.autoPurge === "expired") {
                autoPurgeString = "expired listings";
            } else if (profile.autoPurge === "draft") {
                autoPurgeString = "drafts";
            } else {
                const purgeTime = prettyStringSeconds(
                    profile.autoPurge * 60 * 60,
                    false
                );
                autoPurgeString = `listings older than ${purgeTime}`;
            }

            if (this.isRunning) {
                const postString = `Creating a new listing every ${timeString}`;

                if (profile.autoPurge === "off") {
                    return `${postString}.`;
                } else {
                    return `${postString} and removing ${autoPurgeString}.`;
                }
            } else {
                const postString = `Creates a new listing every ${timeString}`;

                if (profile.autoPurge === "off") {
                    return `${postString}.`;
                } else {
                    return `${postString} and removes ${autoPurgeString}.`;
                }
            }
        }
    },
    methods: {
        async startJob() {
            this.loading = true;
            await apiRequest("POST", "/api/action/startPostJob", {});
            await this.$store.dispatch("fetchJobs");
            this.loading = false;
        },
        async stopJob() {
            this.loading = true;
            await apiRequest("POST", "/api/action/stopJob", {
                jobId: this.$store.state.postJob.jobId
            });
            await this.$store.dispatch("fetchJobs");
            this.loading = false;
        }
    }
};
</script>
