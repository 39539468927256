<template>
    <div :class="classObject">
        <!-- Discord Invite -->

        <a
            class="bar-discord slider"
            href="https://discord.gg/sFF6e2ddwK"
            rel="noopener noreferrer"
            target="_blank"
            v-if="$store.state.profile.showDiscordInvite === true"
        >
            <div>
                <img class="discord-image" src="/Discord_Logo.svg" />
                <div>
                    <b>Click here to join our discord server!</b> Talk to other
                    sellers, get suppliers and more!
                </div>
            </div>
        </a>
        <!-- Dynamic Notification -->

        <div
            class="slider"
            v-if="currentNotification !== null"
            @click="actionClick"
        >
            <div>
                <div>
                    <b class="mr-2" v-if="currentNotification.prefix != null">{{
                        currentNotification.prefix
                    }}</b>
                </div>
                <div>
                    <span>{{ currentNotification.message }}</span>
                </div>
            </div>
        </div>

        <!-- Subscription Warning -->

        <div
            class="bar-warning slider"
            v-if="currentNotification === null && subscriptionWarnText !== null"
            @click="subscriptionWarningClick"
        >
            <div>
                <div>
                    <b class="mr-2">{{ subscriptionWarnText }}</b>
                </div>
                <div>
                    <router-link :to="{ name: 'subscription' }">
                        Buy a subscription
                    </router-link>
                    to keep using AutoFlipper.
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.bar-debug {
    background: var(--success);
}

.bar-info {
    background: var(--info);
}

.bar-warning {
    background: var(--warning);
    color: black;
}

.bar-discord {
    background: #5865f2;
    color: white;
}

.bar-error {
    background: var(--danger);
}
.bar-success {
    background: var(--success);
}

.slider {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;
}

.discord-image {
    height: 3rem;
}
</style>

<script>
export default {
    name: "NotificationBar",
    data() {
        return {};
    },
    computed: {
        currentNotification() {
            return this.$store.state.currentNotification;
        },
        classObject() {
            if (this.currentNotification == null) {
                return {};
            }

            return {
                "bar-debug": this.currentNotification.urgency == "debug",
                "bar-info": this.currentNotification.urgency == "info",
                "bar-warning": this.currentNotification.urgency == "warning",
                "bar-error": this.currentNotification.urgency == "error",
                "bar-success": this.currentNotification.urgency == "success"
            };
        },
        subscriptionWarnText() {
            const validity = this.$store.state.profile.subscriptionValidity;

            if (validity === true) {
                return null;
            } else if (validity === false) {
                return "You do not have a subscription.";
            } else {
                const secsLeft = validity - Date.now() / 1000;
                const daysLeft = Math.floor(secsLeft / 24 / 60 / 60);

                if (daysLeft < 0) {
                    return "Your subscription has expired.";
                }

                if (daysLeft < 1) {
                    return `Your subscription will expire in less than a day.`;
                }

                if (daysLeft === 1) {
                    return `Your subscription will expire in a day.`;
                }

                if (daysLeft < 4) {
                    return `Your subscription will expire in ${daysLeft} days.`;
                }

                return null;
            }
        }
    },
    methods: {
        actionClick() {
            if (this.currentNotification.action == "dismiss") {
                this.$store.commit("dismissCurrentNotification");
            } else {
                console.assert(false, "Unsupported action");
            }
        },
        subscriptionWarningClick() {
            if (this.$router.currentRoute.name !== "subscription") {
                this.$router.push({ name: "subscription" });
            }
        }
    },
    watch: {
        currentNotification() {
            // Set a timeout, if the notification has one
            if (
                this.currentNotification == null ||
                this.currentNotification.timeout == null
            ) {
                return;
            }

            const _this = this;
            const notification = this.currentNotification;
            setTimeout(function() {
                // Make sure the notification hasn't been dismissed by other
                // means in the meantime
                if (notification == _this.currentNotification) {
                    _this.$store.commit("dismissCurrentNotification");
                }
            }, notification.timeout * 1000);
        }
    }
};
</script>
