<template>
    <login-form class="login-form"></login-form>
</template>

<style scoped>
.login-form {
    margin: 5rem auto 2rem auto;
    width: 20rem;
}
</style>

<script>
import LoginForm from "../components/LoginForm.vue";

export default {
    name: "Login",
    components: {
        LoginForm
    },
    beforeRouteEnter(to, from, next) {
        next(async function(vm) {
            // Automatically log in if "loginToken" is provided
            if (to.query.loginToken != undefined) {
                await vm.$store.dispatch(
                    "loginWithSessionToken",
                    to.query.loginToken
                );

                // The log in worked -> leave
                if (vm.$store.state.loggedIn) {
                    next({ name: "app" });
                    return;
                }
            }

            next();
        });
    }
};
</script>
