<template>
    <div>
        <canvas id="viewport"></canvas>
    </div>
</template>

<style scoped>
#viewport {
    width: 100%;
    height: 100%;
}
</style>

<script>
// import { apiRequest } from "../main";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default {
    name: "FortniteBrMap3DView",
    components: {},
    data() {
        return {};
    },
    async mounted() {
        // const mapinfo = await apiRequest("GET", "/api/llamarama/v1/brMap");
        // this.mapImageUrl = mapinfo.imageUrl;

        const canvas = document.querySelector("#viewport");
        const renderer = new THREE.WebGLRenderer({ canvas });

        const fov = 75;
        const aspect = 2;
        const near = 0.01;
        const far = 100;
        const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
        camera.position.z = 2;

        // Create the scene
        const scene = new THREE.Scene();

        {
            const color = 0xffffff;
            const intensity = 1;
            const light = new THREE.DirectionalLight(color, intensity);
            light.position.set(-1, 2, 4);
            scene.add(light);
        }

        // Box
        const boxWidth = 1;
        const boxHeight = 1;
        const boxDepth = 1;
        const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);

        const material = new THREE.MeshPhongMaterial({ color: 0x44aa88 });

        const cube = new THREE.Mesh(geometry, material);
        scene.add(cube);

        // GLTF
        // console.log(GLTFLoader);
        const gltfLoader = new GLTFLoader();
        const url = "https://autoflipper.net/map_3d.glb";
        gltfLoader.load(url, (gltf) => {
            const root = gltf.scene;
            scene.add(root);
        });

        function render(time) {
            time *= 0.001; // convert time to seconds

            cube.rotation.x = time;
            cube.rotation.y = time;

            camera.position.z = time % 100;

            renderer.render(scene, camera);

            requestAnimationFrame(render);
        }
        requestAnimationFrame(render);
    }
};
</script>
