<template>
    <div class="toplevel">
        <div
            class="alert alert-danger"
            role="alert"
            v-show="alert != undefined"
        >
            {{ alert }}
        </div>

        <div class="content-box">
            <div class="setting-text">
                <div class="setting-header text-left">
                    <h5>
                        <label>{{ title }}</label>
                    </h5>
                </div>
                <div class="setting-description text-left">
                    {{ description }}
                </div>
            </div>

            <div class="setting-input">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<style scoped>
label {
    margin: 0;
}

@media (min-width: 55rem) {
    .content-box {
        display: flex;
        flex-direction: row;
    }

    .setting-text {
        width: 50%;
    }

    .setting-input {
        width: 50%;
    }
}
</style>

<script>
export default {
    name: "SettingGroupLoginForm",
    props: ["title", "description", "alert"],
    data: function() {
        return {};
    }
};
</script>
