<template>
    <div id="importEditProductModal" class="modal" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ heading }}</h5>
                </div>

                <!-- Import -->
                <div v-if="state == 'import'">
                    <div class="modal-body">
                        <form>
                            <div
                                class="alert alert-danger"
                                role="alert"
                                v-if="alertText.length != 0"
                            >
                                {{ alertText }}
                            </div>

                            <div>
                                Create a listing on gameflip.
                                <br />
                                Type the link of the listing here to import a
                                product.
                            </div>

                            <label for="listingUrl" class="nd"></label>
                            <input
                                type="text"
                                class="form-control"
                                id="name"
                                v-model="listingUrl"
                                placeholder="Example: https://gameflip.com/item/foobar/639af5ae-2694-426b-9498-f29058a56c93"
                            />
                        </form>
                    </div>
                    <div class="modal-footer">
                        <!-- Dismissing the modal without JavaScript causes odd Behavrior. Do it programmatically -->
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="dismissModal"
                            :disabled="loading"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="importClick"
                            :disabled="!validUrl || loading"
                        >
                            <span v-if="!loading">Import</span>
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                v-if="loading"
                            ></span>
                        </button>
                    </div>
                </div>

                <!-- Edit -->
                <div v-if="state == 'edit'">
                    <div class="modal-body">
                        <div>
                            <div
                                class="alert alert-danger"
                                role="alert"
                                v-if="alertText.length != 0"
                            >
                                {{ alertText }}
                            </div>
                            <product-editor
                                ref="productEditor"
                            ></product-editor>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- Dismissing the modal without JavaScript causes odd Behavrior. Do it programmatically -->
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="dismissModal"
                            :disabled="loading"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger"
                            @click="initiateDeleteClick"
                            :disabled="loading"
                        >
                            <span v-if="!loading">Delete</span>
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                v-if="loading"
                            ></span>
                        </button>
                        <button
                            type="button"
                            class="btn btn-success"
                            @click="saveEditClick"
                            :disabled="loading"
                        >
                            <span v-if="!loading">Save</span>
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                v-if="loading"
                            ></span>
                        </button>
                    </div>
                </div>

                <!-- Confirm Delete -->
                <div v-if="state == 'confirm-delete'">
                    <div class="modal-body">
                        <div
                            class="alert alert-danger"
                            role="alert"
                            v-if="alertText.length != 0"
                        >
                            {{ alertText }}
                        </div>
                        Are you sure you want to
                        <b>delete {{ deleteTargetProductName }}</b
                        >?
                    </div>
                    <div class="modal-footer">
                        <!-- Dismissing the modal without JavaScript causes odd Behavrior. Do it programmatically -->
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="dismissModal"
                            :disabled="loading"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger"
                            @click="confirmDeleteClick"
                        >
                            <span v-if="!loading">Yes, Delete it</span>
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                v-if="loading"
                            ></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>

<script>
import $ from "jquery";
import { apiRequest } from "../main";
import ProductEditor from "./ProductEditor.vue";

export default {
    components: { ProductEditor },
    name: "ImportEditProductDialog",
    data: function() {
        return {
            state: "import", // One of "import", "edit" or "confirm-delete"
            alertText: "",
            loading: false,

            // Import specific
            listingUrl: "",
            validUrl: false,

            // Delete specific
            deleteTargetProductId: undefined,
            deleteTargetProductName: undefined
        };
    },
    computed: {
        heading() {
            if (this.state == "import") {
                return "Import Product";
            }

            if (this.state == "edit") {
                return "Edit Product";
            }

            return "Confirm Deletion";
        }
    },
    methods: {
        goToImport() {
            this.state = "import";
            this.listingUrl = "";
            this.alertText = "";
        },
        goToNew() {
            const profile = this.$store.state.profile;
            this.goToEdit({
                category: profile.defaultCategory,
                deliveryMethod: profile.defaultDeliveryMethod,
                description: "",
                autoPost: true,
                expiresInDays: profile.defaultExpiresInDays,
                gameflipName: null,
                productId: "special:newProduct",
                name: "",
                platform: profile.defaultPlatform,
                priceInCents: 100,
                shipsInDays: profile.defaultShipsInDays,
                tags: {},
                upc: profile.defaultUpc
            });
        },
        goToEdit(product) {
            this.state = "edit";
            this.alertText = "";

            const this_ = this;
            this.$nextTick(function() {
                this_.$refs.productEditor.updateProduct(product, true);
            });
        },
        goToConfirmDelete(productId, productName) {
            this.deleteTargetProductId = productId;
            this.deleteTargetProductName = productName;
            this.state = "confirm-delete";
        },
        async importClick() {
            // Reset state
            this.loading = true;
            this.alertText = "";

            // Import the product
            const response = await apiRequest(
                "POST",
                "/api/action/importListing",
                {
                    listingUrl: this.listingUrl
                }
            );

            this.loading = false;

            // Failure
            if (!response.success) {
                this.alertText = response.message;
                return;
            }

            // Success; Advance to the "edit" stage
            this.goToEdit(response.product);
            this.$store.dispatch("fetchProducts");
        },
        initiateDeleteClick() {
            const product = this.$refs.productEditor.getProduct();
            this.goToConfirmDelete(product.productId, product.name);
        },
        async saveEditClick() {
            this.loading = true;
            this.alertText = "";

            // Save the changes
            const productEditor = this.$refs.productEditor;
            const product = productEditor.getProduct();

            // First create the product. This will automatically assign the
            // default product image.
            const response = await apiRequest(
                "POST",
                `/api/product/${product.productId}`,
                {
                    product
                }
            );

            // Override the image if the user has provided one
            if (response.success && productEditor.userHasChangedProductImage) {
                productEditor.updateProduct({
                    productId: response.product.productId
                });

                const img_response = await productEditor.postProductImage();

                if (!img_response.success) {
                    this.loading = false;
                    this.alertText = `Could not change the product image: ${img_response.message}`;
                    return;
                }
            }

            this.loading = false;

            // Failure
            if (!response.success) {
                this.alertText = response.message;
                return;
            }

            // Success; Dismiss the dialog
            // These can run in parallel - no need to await
            this.dismissModal();
            this.$store.dispatch("fetchProducts");
        },
        async confirmDeleteClick() {
            this.loading = true;
            this.alertText = "";

            // Save the changes
            const response = await apiRequest(
                "DELETE",
                `/api/product/${this.deleteTargetProductId}`
            );

            this.loading = false;

            // Failure
            if (!response.success) {
                this.alertText = response.message;
                return;
            }

            // Success; Dismiss the dialog
            // These can run in parallel - no need to await
            this.dismissModal();
            this.$store.dispatch("fetchProducts");
        },
        async dismissModal() {
            $("#importEditProductModal").modal("hide");
        }
    },
    watch: {
        listingUrl: function(newUrl) {
            // Make sure the URL contains a UUID. The listing's id is all that's
            // needed to find it
            const idRegex = /[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/;
            if (!idRegex.test(newUrl)) {
                this.validURL = false;
                this.alertText =
                    "The link does not point to a gameflip listing.";
                return;
            }

            this.validUrl = true;
            this.alertText = "";
        }
    }
};
</script>
