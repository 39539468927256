<template>
    <div class="product-item" @click="$emit('edit-click')">
        <span class="product-text">{{ product.name }}</span>

        <img
            class="product-button"
            v-if="!loading"
            @click.stop="createListing"
            src="/icons/play-fill.svg"
            data-toggle="tooltip"
            data-placement="top"
            title="Create Listing"
            width="25rem"
            height="25rem"
        />

        <img
            class="product-button"
            v-if="!loading"
            @click.stop="toggleAutoPost"
            :src="switchImageUrl"
            data-toggle="tooltip"
            data-placement="top"
            title="Auto-post"
            width="25rem"
            height="25rem"
        />

        <span
            class="spinner-border spinner-border-sm product-button"
            role="status"
            v-if="loading"
        ></span>
    </div>
</template>

<style scoped>
.product-text {
    float: left;
    max-width: 65%;
}

.product-button {
    float: right;
    margin-left: 1rem;
}

.product-item {
    cursor: pointer;
    transition: background-color 0.3s;
}

.product-item:hover {
    background-color: gray;
}
</style>

<script>
// import $ from "jquery";
import { apiRequest } from "../main.js";

export default {
    name: "ProductItem",
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false
        };
    },
    computed: {
        switchImageUrl() {
            if (this.product.autoPost == true) {
                return "/icons/toggle2-on.svg";
            } else {
                return "/icons/toggle2-off-custom.svg";
            }
        }
    },
    methods: {
        async toggleAutoPost() {
            const product = Object.assign({}, this.product);
            product.autoPost = !this.product.autoPost;

            this.loading = true;

            // Update the product
            await apiRequest("POST", `/api/product/${product.productId}`, {
                product
            });

            // Update the products - doesn't have to be awaited
            await this.$store.dispatch("fetchProducts", product);

            this.loading = false;
        },
        async createListing() {
            // Post the product
            this.loading = true;

            const response = await apiRequest(
                "POST",
                "/api/action/postListing",
                {
                    productId: this.product.productId
                }
            );

            this.loading = false;

            if (response.success) {
                this.$store.commit("addNotification", {
                    urgency: "success",
                    prefix: "Success!",
                    message: `Posted ${this.product.name} to gameflip.`,
                    timeout: 5
                });
            } else if (response.slug === "rate-limit-exceeded") {
                alert("No spamming please!")
            } else {
                this.$store.commit("addNotification", {
                    urgency: "error",
                    prefix: "Could not create the listing",
                    message: response.message
                });
            }
        }
    },
    // mounted() {
    //     // Enable tooltips
    //     $('[data-toggle="tooltip"]').tooltip();
    // },
    // updated() {
    //     // Update tooltips
    //     $('[data-toggle="tooltip"]').tooltip("update");
    // },
    // beforeDestroy() {
    //     // Disable tooltips
    //     $('[data-toggle="tooltip"]').tooltip("dispose");
    // }
};
</script>
