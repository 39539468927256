<template>
    <div>
        <notification-bar ref="notificationBar"></notification-bar>

        <div class="content-box">
            <router-view></router-view>
        </div>
    </div>
</template>

<style scoped>
@media (max-width: 55rem) {
    .content-box {
        width: 90%;
        margin: 2rem 5% 2rem 5%;
    }
}

@media (min-width: 55rem) {
    .content-box {
        margin: 3rem;
    }
}
</style>

<script>
import NotificationBar from "../components/NotificationBar.vue";
import { store } from "../main.js";

function routeGuard(to, from, next) {
    if (to.name != "link-gameflip" && !store.state.profile.gameflipIsLinked) {
        next({ name: "link-gameflip", query: { setupUser: true } });
        return;
    }

    next();
}

export default {
    name: "InApp",
    components: {
        NotificationBar
    },
    beforeRouteEnter: routeGuard,
    beforeRouteUpdate: routeGuard
};
</script>
