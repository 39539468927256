<template>
    <!-- Without this div the boxes are rendered incorrectly in Safari. They are
    stacked on top of each other for some reason -->
    <div>
        <a
            class="toplevel-subscription-tier-box"
            :href="purchaseUrl"
            rel="noopener noreferrer"
            target="_blank"
        >
            <div class="inner">
                <h2>{{ tierInfo.nicename }}</h2>

                <div class="mb-3">
                    <i>{{ tierInfo.blurp }}</i>
                </div>

                <div>
                    1 Post every
                    {{
                        prettyStringSeconds(
                            tierInfo.minimumTimeBetweenPosts,
                            false
                        )
                    }}
                </div>

                <div>Up to {{ tierInfo.maxNumberOfProducts }} Products</div>

                <div v-if="tierInfo.allowFortniteApi">
                    <b>
                        Fortnite: Rename your homebase and have the bot collect
                        your free upgrade llamas automatically.
                    </b>
                </div>

                <div v-for="note in tierInfo.additionalNotes" :key="note">
                    {{ note }}
                </div>

                <!-- Spacer -->
                <div class="mb-3"></div>

                <div class="price-text">
                    <i>{{ tierInfo.pricePerThirtyDays }}$ for 30 days</i>
                </div>
            </div>

            <button type="button" class="btn btn-success buy-button">Buy</button>
        </a>
    </div>
</template>

<style scoped>
.toplevel-subscription-tier-box {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    height: 100%;

    color: inherit;
    text-decoration: none;
}

.inner {
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    padding: 1rem;
    border-style: solid;
    border-radius: 0.3rem;
    border-width: 0.1rem;
    border-color: grey;

    transition: 0.2s;
}

h2 {
    transition: 0.2s;
}

.toplevel-subscription-tier-box:hover h2 {
    color: var(--highlight-primary);
}

.toplevel-subscription-tier-box:hover .inner {
    border-color: var(--highlight-primary);
}

.price-text {
    margin-top: auto;
}

.buy-button {
    margin-top: 1rem;
    width: 100%;
}
</style>

<script>
import { prettyStringSeconds } from "../main.js";

export default {
    name: "SubscriptionTierBox",
    props: {
        tierInfo: {
            type: Object,
            required: true
        }
    },
    computed: {
        purchaseUrl() {
            // return `https://gameflip.com/profile/us-east-1:cac20619-48a7-4ebc-aaf2-6985b553b794?term=${this.tierInfo.nicename}`;
            return "https://gameflip.com/profile/us-east-1:cac20619-48a7-4ebc-aaf2-6985b553b794";
        }
    },
    methods: {
        prettyStringSeconds(seconds, midsentence) {
            return prettyStringSeconds(seconds, midsentence);
        }
    }
};
</script>
