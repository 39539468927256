<template>
    <div class="column-container">
        <div class="column">
            <button
                type="button"
                class="btn btn-primary toplevel-button"
                data-toggle="modal"
                data-target="#postCustomBundleModal"
                @click="postCustomBundleClick"
            >
                Post Custom Bundles
            </button>
            <post-job-card></post-job-card>
            <purge-job-card></purge-job-card>
        </div>

        <div class="column">
            <button
                type="button"
                class="btn btn-primary toplevel-button"
                @click="importProductClick"
            >
                Import Product
            </button>
            <button
                type="button"
                class="btn btn-secondary toplevel-button"
                @click="createProductClick"
            >
                Create Product
            </button>

            <div class="list-group">
                <input
                    type="text"
                    class="list-group-item filter-box"
                    placeholder="Search..."
                    v-if="products.length >= 6"
                    v-model="productFilterText"
                />
            </div>

            <div v-for="group in groupedFilteredProducts" :key="group.name">
                <h5 v-if="groupedFilteredProducts.length !== 1" class="mt-4 mb-3">
                    {{ group.nicename }}
                </h5>
                <div class="list-group">
                    <product-item
                        class="list-group-item"
                        :product="prd"
                        v-for="prd in group.products"
                        :key="prd.productId"
                        @edit-click="editProductClick(prd)"
                    ></product-item>
                </div>
            </div>
        </div>

        <!-- Modal Dialogs -->
        <import-edit-product-dialog
            ref="importEditProductModal"
        ></import-edit-product-dialog>
        <post-custom-bundle-dialog
            ref="postCustomBundleModal"
        ></post-custom-bundle-dialog>
    </div>
</template>

<style scoped>
.column > * {
    margin-bottom: 0.8rem;
}

.toplevel-button {
    width: 100%;
}

.product-text {
    float: left;
    max-width: 65%;
}

.filter-box {
    color: var(--legible-light);
}

@media (min-width: 57rem) {
    .column-container {
        max-width: 60rem;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;
    }

    .column {
        width: 25rem;
    }
}
</style>

<script>
import $ from "jquery";
import ImportEditProductDialog from "../components/ImportEditProductDialog.vue";
import PostCustomBundleDialog from "../components/PostCustomBundleDialog.vue";
import PostJobCard from "../components/PostJobCard.vue";
import ProductItem from "../components/ProductItem.vue";
import PurgeJobCard from "../components/PurgeJobCard.vue";

export default {
    name: "Dashboard",
    components: {
        ImportEditProductDialog,
        PostCustomBundleDialog,
        PostJobCard,
        ProductItem,
        PurgeJobCard
    },
    data() {
        return {
            productFilterText: ""
        };
    },
    computed: {
        products() {
            return this.$store.state.products;
        },
        filteredProducts() {
            let filter = this.productFilterText.trim().toLowerCase();
            if (filter.length == 0) {
                return this.products;
            }

            return this.products.filter(v =>
                v.name.toLowerCase().includes(filter)
            );
        },
        groupedFilteredProducts() {
            // Group the products
            let byGroupName = {};
            for (let product of this.filteredProducts) {
                let groupName = product.upc;

                // Get the group
                let old = byGroupName[groupName];

                if (old === undefined) {
                    old = [];
                    byGroupName[groupName] = old;
                }

                // Add the product
                old.push(product);
            }

            // Postprocess the groups
            const result = [];
            for (let groupName in byGroupName) {
                // All disabled?
                const products = byGroupName[groupName];
                let allDisabled = true;
                for (let product of products) {
                    if (product.autoPost === false) {
                        allDisabled = false;
                        break;
                    }
                }

                // Better group name
                for (let upc of this.$store.state.upcInformation) {
                    if (upc.upcId === groupName) {
                        groupName = upc.nicename;
                        break;
                    }
                }

                // Create the group
                result.push({
                    nicename: groupName,
                    allDisabled: allDisabled,
                    products: products
                });
            }

            // Sort them
            result.sort(function(a, b) {
                return a.nicename.localeCompare(b.nicename);
            });

            return result;
        }
    },
    methods: {
        postCustomBundleClick() {
            this.$refs.postCustomBundleModal.reset();
        },
        importProductClick() {
            this.$refs.importEditProductModal.goToImport();
            $("#importEditProductModal").modal("show");
        },
        createProductClick() {
            this.$refs.importEditProductModal.goToNew();
            $("#importEditProductModal").modal("show");
        },
        editProductClick(product) {
            this.$refs.importEditProductModal.goToEdit(product);
            $("#importEditProductModal").modal("show");
        }
    }
};
</script>
