<template>
    <div>
        <svg id="br-map-svg" class="container-border"></svg>
    </div>
</template>

<style scoped>
.foo {
    font-size: 23;
}
</style>

<script>
import * as d3 from "d3";
import { apiRequest } from "../main";

export default {
    name: "FortniteBrMapView",
    components: {},
    data() {
        return {
            mapImageUrl: "",
            labels: [],
            icons: [],
            zoomLevel: 1.0,
            translateX: 0,
            translateY: 0
        };
    },
    computed: {
        minImportance() {
            return (4 - this.zoomLevel + 1) / 4;
        }
    },
    async mounted() {
        const mapinfo = await apiRequest("GET", "/api/llamarama/v1/brMap");

        this.mapImageUrl = mapinfo.imageUrl;

        // Preprocess the markers
        for (var group of mapinfo.markers) {
            for (var marker of group.markers) {
                // Label
                const label = marker.label || group.label;

                if (label !== null) {
                    this.labels.push({
                        text: label,
                        size: 30 * group.importance,
                        x: marker.x,
                        y: marker.y,
                        importance: group.importance
                    });
                }

                // Image
                const imageUrl = marker.imageUrl || group.imageUrl;

                if (imageUrl !== null) {
                    this.icons.push({
                        imageUrl: imageUrl,
                        size: 50 * group.importance,
                        x: marker.x,
                        y: marker.y,
                        importance: group.importance
                    });
                }
            }
        }

        this.createMap();
    },
    methods: {
        createMap() {
            // Update the SVG
            var squareSize = 1024;

            const svg = d3
                .select("#br-map-svg")
                .attr("width", squareSize)
                .attr("height", squareSize);

            // Map Image
            svg.append("image")
                .attr("width", 1000 + "px")
                .attr("height", 1000 + "px")
                .attr("xlink:href", this.mapImageUrl);

            const xCoord = marker => {
                return marker.x * squareSize * this.zoomLevel + this.translateX;
            };

            const yCoord = marker => {
                return marker.y * squareSize * this.zoomLevel + this.translateY;
            };

            const markers = svg.append("g");

            // Draw the icons
            markers
                .selectAll("markers")
                .data(this.icons)
                .enter()
                .append("image")
                .attr("class", "marker-icon")
                .attr("width", d => d.size)
                .attr("height", d => d.size)
                .attr("xlink:href", d => d.imageUrl);

            // Draw the labels
            markers
                .selectAll("markers")
                .data(this.labels)
                .enter()
                .append("text")
                .attr("class", "marker-label")
                .text(d => d.text)
                .attr("text-anchor", "middle")
                .attr("fill", "black")
                .attr("stroke", "black")
                .attr("stroke-width", 4)
                .attr("font-size", d => d.size)
                .attr("font-weight", 700);

            markers
                .selectAll("markers")
                .data(this.labels)
                .enter()
                .append("text")
                .attr("class", "marker-label")
                .text(d => d.text)
                .attr("text-anchor", "middle")
                .attr("fill", "white")
                .attr("font-size", d => d.size)
                .attr("font-weight", 700);

            const onZoom = event => {
                this.zoomLevel = event.transform.k;
                this.translateX = event.transform.x;
                this.translateY = event.transform.y;

                svg.select("image").attr("transform", event.transform);

                updateMarkers();
            };

            const updateMarkers = () => {
                const minImportance = this.minImportance;
                function visible(marker) {
                    if (marker.importance >= minImportance) {
                        return "visible";
                    } else {
                        return "hidden";
                    }
                }

                markers
                    .selectAll(".marker-icon")
                    .attr("x", xCoord)
                    .attr("y", yCoord)
                    .attr("visibility", visible);

                markers
                    .selectAll(".marker-label")
                    .attr("dx", xCoord)
                    .attr("dy", yCoord)
                    .attr("visibility", visible);
            };

            var zoom = d3
                .zoom()
                .scaleExtent([1, 8])
                .on("zoom", onZoom);

            svg.call(zoom);
            updateMarkers();
        }
    }
};
</script>
