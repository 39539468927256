import Vue from "vue";
import VueRouter from "vue-router";

import Dashboard from "../views/Dashboard.vue";
import FortniteView from "../views/FortniteView.vue";
import FortniteInventoryView from "../views/FortniteInventoryView.vue";
import HomeView from "../views/HomeView.vue";
import InApp from "../views/InApp.vue";
import LinkGameflipView from "../views/LinkGameflipView.vue";
import Login from "../views/Login.vue";
import RegisterView from "../views/RegisterView.vue";
import SettingsView from "../views/SettingsView.vue";
import SubscriptionView from "../views/SubscriptionView.vue";
import FortniteBrMapView from "../views/FortniteBrMapView.vue";
import FortniteBrMap3DView from "../views/FortniteBrMap3DView.vue";

Vue.use(VueRouter);

const routes = [
    {
        name: "home",
        path: "/",
        component: HomeView,
        meta: {
            allowIfLoggedIn: false
        }
    },
    {
        name: "app",
        path: "/app",
        component: InApp,
        redirect: { name: "dashboard" },
        meta: {
            allowIfLoggedOut: false
        },
        children: [
            {
                name: "dashboard",
                path: "dashboard",
                component: Dashboard
            },
            {
                name: "settings",
                path: "settings",
                component: SettingsView
            },
            {
                name: "subscription",
                path: "subscription",
                component: SubscriptionView
            },
            {
                name: "link-gameflip",
                path: "link-gameflip",
                component: LinkGameflipView
            },
            {
                name: "fortnite",
                path: "fortnite",
                component: FortniteView
            },
            {
                name: "fortnite-inventory",
                path: "fortnite-inventory",
                component: FortniteInventoryView
            }
        ]
    },
    {
        name: "login",
        path: "/login",
        component: Login,
        meta: {
            allowIfLoggedIn: false
        }
    },
    {
        name: "register",
        path: "/register",
        component: RegisterView,
        meta: {
            allowIfLoggedIn: false
        }
    },
    {
        name: "fortnite-br-map",
        path: "/brmap",
        component: FortniteBrMapView
    },
    {
        name: "fortnite-br-map-3d",
        path: "/brmap3d",
        component: FortniteBrMap3DView
    },
    {
        // Catch-all: Redirect users that attempt to visit invalid routes
        path: "*",
        redirect: "/"
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
