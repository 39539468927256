import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import "bootstrap";
import "./style.scss";

Vue.use(Vuex);

export async function apiRequest(method, route, body, quiet) {
    // Normalize quiet
    quiet = quiet == true;

    // Send the request
    var responseBody;

    try {
        var response;
        if (method == "GET") {
            response = await axios.get(route);
        } else if (method == "POST") {
            response = await axios.post(route, body);
        } else if (method == "DELETE") {
            response = await axios.delete(route);
        } else {
            throw "Unsupported method";
        }

        responseBody = response.data;
    } catch (e) {
        responseBody = {
            success: false,
            message: "Exception in API request", // TODO set a proper message
            slug: null,
            exception: e
        };
    }

    // Handle common errors
    if (!responseBody.success) {
        if (!quiet) {
            console.log("Error in API request. Response follows:");
            console.log(responseBody);
        }

        // The user is not logged in -> switch to login screen
        if (responseBody.slug == "not-logged-in" && !quiet) {
            store.commit("clearUserVariables");

            if (router.currentRoute.name != "login") {
                router.push({ name: "login" });
            }
        }
    }

    return responseBody;
}

// Navigation guard
//   Signed-Out users can't access pages that require authentication
//   Signed-In users can't access pages that are only meant for not-yet-users
router.beforeEach(async function(to, from, next) {
    // Attempt to resume a previous session
    if (!store.state.initialLoginAttempted) {
        await store.dispatch("fetchProfile", true);
        store.commit("markInitialLoginAttempted");

        // Success - fetch all globals
        if (store.state.loggedIn) {
            await store.dispatch("fetchAllGlobals");
        }
    }

    // Can this route be accessed if the user is logged in/out
    const allowLoggedIn = !to.matched.some(
        record => record.meta.allowIfLoggedIn == false
    );
    const allowLoggedOut = !to.matched.some(
        record => record.meta.allowIfLoggedOut == false
    );

    // Navigate to the correct route
    if (allowLoggedIn && store.state.loggedIn) {
        next();
        return;
    }

    if (allowLoggedOut && !store.state.loggedIn) {
        next();
        return;
    }

    if (store.state.loggedIn) {
        next({ name: "app" });
    } else {
        next({ name: "login" });
    }
});

export function prettyStringSeconds(seconds, midsentence) {
    console.assert(Math.trunc(seconds) == seconds);
    console.assert(seconds >= 0);

    if (seconds == 0) {
        return "0 seconds";
    }

    const units = [
        ["second", 60],
        ["minute", 60],
        ["hour", 24],
        ["day", null]
    ];

    const parts = [];
    var amount = seconds;

    for (var ii = 0; ii < units.length; ++ii) {
        const unitName = units[ii][0];
        const unitFactor = units[ii][1];

        var cur;
        if (unitFactor == null) {
            cur = amount;
        } else {
            cur = amount % unitFactor;
            amount = Math.floor(amount / unitFactor);
        }

        if (cur == 0) {
            continue;
        }

        parts.push([unitName, cur]);
    }

    parts.reverse();
    const chunks = [];
    for (var jj = 0; jj < parts.length; ++jj) {
        const unitName = parts[jj][0];
        const amount = parts[jj][1];

        if (amount == 1) {
            if (midsentence && chunks.length == 0) {
                chunks.push(unitName);
            } else {
                chunks.push(`1 ${unitName}`);
            }
        } else {
            chunks.push(`${amount} ${unitName}s`);
        }
    }

    return chunks.join(" ");
}

var lastPollTime = 0;
var lastActionTime = 0;

async function pollLoop() {
    // How long to sleep
    const now = Date.now() / 1000;
    const timeSinceLastAction = now - lastActionTime;
    const sleepTime = Math.min(Math.max(timeSinceLastAction, 3), 30);

    // Tick
    const timeSinceLastPoll = now - lastPollTime;
    if (timeSinceLastPoll >= sleepTime) {
        lastPollTime = now;

        try {
            await pollLoopTick();
        } catch (err) {
            console.log("Error while polling");
            console.log(err);
        }
    }

    // Sleep
    setTimeout(pollLoop, Math.min(sleepTime, 5) * 1000);
}

async function pollLoopTick() {
    // Early out checks: Don't refresh unnecessarily
    // Not logged in - don't even try
    if (!store.state.loggedIn || document.hidden) {
        return;
    }

    // Get the event cursor
    // const a = localStorage.getItem("foo");
    // const a = null ?? (Date.now() / 1000)
    var eventCursor = localStorage.getItem("eventCursor");
    if (eventCursor == null) {
        eventCursor = Date.now() / 1000;
    }

    // Get all events since the last tick
    const response = await apiRequest(
        "GET",
        "/api/me/events?cursor=" + String(eventCursor)
    );

    if (response.success) {
        localStorage.setItem("eventCursor", response.cursor);
        await processEvents(response.events);
    }
}

async function processEvents(events) {
    // Dirty flags: Mark all things that have changed since the last poll
    var profileChanged = false;
    var productsChanged = false;
    var jobsChanged = false;

    // Iterate over all events
    for (var ii = 0; ii < events.length; ++ii) {
        const evt = events[ii];
        const evType = evt["type"];

        if (evType == "job-updated") {
            jobsChanged = true;
            continue;
        }

        if (evType == "user-updated") {
            profileChanged = true;
            continue;
        }

        if (evType == "product-updated") {
            productsChanged = true;
            continue;
        }

        if (evType == "notify") {
            store.commit("addNotification", {
                urgency: evt.urgency,
                prefix: evt.prefix,
                message: evt.message,
                timeout: 25
            });
            continue;
        }
    }

    // Update any globals that have changed
    const awaits = [];

    if (profileChanged) {
        awaits.push(store.dispatch("fetchProfile"));
    }

    if (productsChanged) {
        awaits.push(store.dispatch("fetchProducts"));
    }

    if (jobsChanged) {
        awaits.push(store.dispatch("fetchJobs"));
    }

    // Was there something to do?
    if (awaits.length != 0) {
        lastActionTime = Date.now() / 1000;
    }

    await Promise.all(awaits);
}

export function looksLikeValidUsername(usernameString) {
    return /[a-zA-Z_][a-zA-Z_0-9]*/.test(usernameString);
}

export function looksLikeValidApiKey(keyString) {
    return /[a-f0-9]+/.test(keyString);
}

export function looksLikeValidApiSecret(secretString) {
    return /[A-Z0-9]+/.test(secretString);
}

export const store = new Vuex.Store({
    state: {
        pendingNotifications: [],
        currentNotification: null,
        initialLoginAttempted: false,
        loggedIn: false,
        profile: null,
        products: [],
        postJob: null,
        purgeJob: null,
        productCategories: [],
        upcInformation: [],
        productDeliveryMethods: [],
        productPlatforms: [],
        subscriptionTiers: {}
    },
    mutations: {
        addNotification(state, notification) {
            const notificationBase = {
                prefix: null,
                action: "dismiss",
                timeout: null
            };

            let fullNotification = Object.assign(
                notificationBase,
                notification
            );

            state.pendingNotifications.push(fullNotification);
            this.commit("_updateCurrentNotification");
        },
        dismissCurrentNotification(state) {
            state.currentNotification = null;
            this.commit("_updateCurrentNotification");
        },
        _updateCurrentNotification(state) {
            // Already displaying a notification
            if (state.currentNotification != null) {
                return;
            }

            // There is no notifications
            if (state.pendingNotifications.length == 0) {
                return;
            }

            // Fetch a new notification
            // TODO: be smarter about which notification to pop
            state.currentNotification = state.pendingNotifications.pop();
        },
        markInitialLoginAttempted(state) {
            state.initialLoginAttempted = true;
        },
        clearUserVariables(state) {
            state.pendingNotifications = [];
            state.currentNotification = null;
            state.loggedIn = false;
            state.profile = null;
            state.products = [];
            state.postJob = null;
            state.purgeJob = null;
        },
        setProfile(state, value) {
            state.profile = value;
            state.loggedIn = value != null;
        },
        setProducts(state, value) {
            state.products = value;
        },
        setPostJob(state, value) {
            state.postJob = value;
        },
        setPurgeJob(state, value) {
            state.purgeJob = value;
        },
        setMeta(
            state,
            {
                productCategories,
                upcInformation,
                productDeliveryMethods,
                productPlatforms,
                subscriptionTiers
            }
        ) {
            state.productCategories = productCategories;
            state.upcInformation = upcInformation;
            state.productDeliveryMethods = productDeliveryMethods;
            state.productPlatforms = productPlatforms;
            state.subscriptionTiers = subscriptionTiers;
        },
        setLinkedEpicAccounts(state, accounts) {
            state.linkedEpicAccounts = accounts;
        }
    },
    actions: {
        async fetchAllGlobals() {
            await Promise.all([
                this.dispatch("fetchProfile"),
                this.dispatch("fetchJobs"),
                this.dispatch("fetchProducts"),
                this.dispatch("fetchMeta"),
                this.dispatch("fetchLinkedEpicAccounts")
            ]);
        },
        async createNewAccount(state, { username, password, introducedBy }) {
            const response = await apiRequest(
                "POST",
                "/api/action/createNewAccount",
                {
                    username: username.trim(),
                    password: password.trim(),
                    introducedBy: introducedBy,
                    trialDuration: 7 * 24 * 60 * 60
                }
            );

            // Account creation failed
            if (!response.success) {
                return response;
            }

            return await this.dispatch("finishLogin", response);
        },
        async loginWithUsernameAndPassword(state, { username, password }) {
            const response = await apiRequest(
                "POST",
                "/api/action/loginWithUsernameAndPassword",
                {
                    username: username.trim(),
                    password: password.trim()
                }
            );

            // Login failed
            if (!response.success) {
                return response;
            }

            return await this.dispatch("finishLogin", response);
        },
        async loginWithSessionToken(state, token) {
            const response = await apiRequest(
                "POST",
                "/api/action/loginWithSessionToken",
                {
                    token
                }
            );

            // Login failed
            if (!response.success) {
                return response;
            }

            return await this.dispatch("finishLogin", response);
        },
        async finishLogin(state, response) {
            // Get all user variables
            await this.dispatch("fetchAllGlobals");

            // Redirect to the user's dashboard
            router.push({ name: "app" });
            return response;
        },
        async logout(state) {
            await apiRequest("POST", "/api/action/endSession");

            // Prevent the router from navigating straight back
            state.commit("setProfile", null);

            // Redirect to the homepage
            await router.push({ name: "home" });

            // Clear global state
            await state.commit("clearUserVariables");
        },
        async redeemKey(state, keyCode) {
            const response = await apiRequest("POST", "/api/action/redeemKey", {
                keyCode
            });

            return response;
        },
        async fetchMeta(state) {
            const response = await apiRequest("GET", "/api/meta");

            if (!response.success) {
                return;
            }

            state.commit("setMeta", response);
        },
        async fetchLinkedEpicAccounts(state) {
            const response = await apiRequest(
                "GET",
                "/api/fortnite/linkedEpicAccounts"
            );

            if (!response.success) {
                console.log(response);
                return;
            }

            response.accounts.sort(function(a, b) {
                return b.powerLevel - a.powerLevel;
            });

            state.commit("setLinkedEpicAccounts", response.accounts);
        },
        async fetchProfile(state, quiet) {
            const response = await apiRequest(
                "GET",
                "/api/me/profile",
                undefined,
                quiet
            );

            if (!response.success) {
                return;
            }

            state.commit("setProfile", response.user);
        },
        async fetchProducts(state) {
            const response = await apiRequest("GET", "/api/me/products");

            if (!response.success) {
                return;
            }

            // Sort the products alphabetically
            response.products.sort(function(a, b) {
                return a.name.localeCompare(b.name);
            });

            state.commit("setProducts", response.products);
        },
        async fetchJobs(state) {
            const response = await apiRequest("GET", "/api/me/jobs");

            if (!response.success) {
                return;
            }

            state.commit("setPostJob", response.post);
            state.commit("setPurgeJob", response.purge);
        },
        async startPolling() {
            pollLoop();
        }
    }
});

new Vue({
    router,
    render: function(h) {
        return h(App);
    },
    store
}).$mount("#app");
