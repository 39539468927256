<template>
    <div class="toplevel">
        <div class="background-container"></div>

        <div class="content-box">
            <div
                id="carouselExampleSlidesOnly"
                class="carousel slide"
                data-ride="carousel"
                ref="carousel"
                data-interval="3000"
            >
                <!-- <div class="carousel-inner">
                    <div class="carousel-item active">
                        <h1>Page 1</h1>
                    </div>
                    <div class="carousel-item">
                        <h1>Page 2</h1>
                    </div>
                    <div class="carousel-item">
                        <h1>Page 3</h1>
                    </div>
                </div>

                <a
                    class="carousel-control-prev"
                    href="#carouselExampleControls"
                    role="button"
                    data-slide="prev"
                >
                    <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a
                    class="carousel-control-next"
                    href="#carouselExampleControls"
                    role="button"
                    data-slide="next"
                >
                    <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                </a> -->
            </div>

            <div>
                <login-form class="login-box"></login-form>
            </div>
        </div>
    </div>
</template>

<style scoped>
.background-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.content-box {
    display: flex;
    margin: 5rem 10% 0 10%;
    justify-content: space-between;
}

.carousel {
    flex-grow: 1;
    height: 50rem;
    margin-right: 5rem;
    /* background-color: blue; */
}

.login-box {
    width: 20rem;
}
</style>

<script>
import LoginForm from "../components/LoginForm.vue";
import $ from "jquery";

export default {
    name: "HomeView",
    components: {
        LoginForm
    },
    mounted() {
        $("#carouselExampleSlidesOnly").carousel();
    //     setInterval(function() {
    //         const carousel = this.$refs.carousel;
    //         const jq_carousel = $(carousel);
    //         // jq_carousel.carousel();
    //         console.log(jq_carousel);
    //     }.bind(this), 3000);
    }
};
</script>
