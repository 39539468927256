<template>
    <div @click="expanded = !expanded">
        <div class="top-bar">
            <div class="top-bar-left">
                <div v-if="imageUrl !== null" class="item-image">
                    <div :class="bgClass"></div>
                    <img
                        :src="imageUrl"
                        :alt="nicename"
                        class="item-image-image"
                    />
                </div>
                <div class="item-image" v-else></div>
                <h6 class="text-left ml-3">
                    {{ nicename }} - {{ formatAmount(totalAmount) }}
                </h6>
            </div>
        </div>

        <div v-if="expanded">
            <div
                v-for="pair in sortedAmounts"
                :key="pair[0]"
                class="text-left ml-5"
            >
                {{ formatAmount(pair[1]) }} on {{ pair[0] }}
            </div>
        </div>
    </div>
</template>

<style scoped>
.top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.top-bar-left {
    display: flex;
    flex-direction: row;
}

.item-image {
    width: 2.5rem;
    height: 2.5rem;
    position: relative;
}

.item-image-bg {
    width: 100%;
    height: 100%;
    border-radius: 0.3rem;
}
.item-image-image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.bg-rarity-common {
    background: radial-gradient(rgb(187, 187, 187), rgb(105, 105, 105));
}
.bg-rarity-uncommon {
    background: radial-gradient(rgb(137, 199, 135), rgb(48, 192, 48));
}
.bg-rarity-rare {
    background: radial-gradient(rgb(101, 123, 196), rgb(30, 87, 194));
}
.bg-rarity-epic {
    background: radial-gradient(rgb(180, 107, 164), rgb(156, 32, 160));
}
.bg-rarity-legendary {
    background: radial-gradient(rgb(214, 198, 107), rgb(204, 164, 34));
}
.bg-rarity-mythic {
    background: radial-gradient(rgb(226, 216, 152), rgb(189, 180, 59));
}
</style>

<script>
export default {
    name: "FortniteInventoryView",
    props: {
        templateId: String,
        nicename: String,
        rarity: String,
        powerLevel: Number,
        imageUrl: String,
        amounts: Object
    },
    data() {
        return {
            expanded: false
        };
    },
    computed: {
        totalAmount() {
            var result = 0;

            for (var n of Object.values(this.amounts)) {
                result += n;
            }

            return result;
        },
        sortedAmounts() {
            var result = Object.entries(this.amounts);
            result = result.sort(function(a, b) {
                return a[1] < b[1];
            });
            return result;
        },
        bgClass() {
            const result = {
                "item-image-bg": true
            };
            result[`bg-rarity-${this.rarity}`] = true;

            return result;
        }
    },
    methods: {
        formatAmount(amount) {
            if (amount < 10000) {
                return `${amount}`;
            }

            let div, suffix;
            if (amount >= 1000000) {
                div = 1000000;
                suffix = "M";
            } else {
                div = 1000;
                suffix = "K";
            }

            let smaller = amount / div;
            let int_part = Math.floor(smaller);
            let frac_part = Math.floor((smaller - int_part) * 10);

            if (frac_part == 0) {
                return `${int_part}${suffix}`;
            }
            return `${int_part}.${frac_part}${suffix}`;
        }
    }
};
</script>
