<template>
    <div class="main-container">
        <h1 class="mb-4">Create Your Account</h1>

        <div class="card">
            <div class="card-body">
                <div
                    class="alert alert-danger"
                    role="alert"
                    v-if="alertText.length != 0"
                >
                    {{ alertText }}
                </div>

                <div class="column-container">
                    <div class="column1">
                        <img
                            class="mb-4"
                            src="bot_icon_color.png"
                            alt=""
                            height="120rem"
                        />
                    </div>

                    <form class="column2">
                        <div class="form-group">
                            <label for="inputUsername" class="form-elem"
                                >Username</label
                            >
                            <input
                                type="text"
                                id="inputUsername"
                                class="form-control form-elem"
                                v-model="username"
                                autofocus
                            />
                        </div>

                        <div class="form-group">
                            <label for="inputPassword1" class="form-elem"
                                >Password</label
                            >
                            <input
                                type="password"
                                id="inputPassword1"
                                class="form-control form-elem"
                                v-model="password1"
                            />
                            <input
                                type="password"
                                id="inputPassword2"
                                class="form-control form-elem mt-1"
                                v-model="password2"
                                placeholder="Repeat Password"
                            />
                        </div>

                        <button
                            type="button"
                            id="createAccountButton"
                            class="w-100 btn btn-lg btn-primary"
                            :disabled="loading || fieldsAreEmpty"
                            @click="clickCreateAccount()"
                        >
                            <span v-if="!loading">Create Account</span>
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                v-if="loading"
                            ></span>
                        </button>
                        <div class="mt-3">Already have an Account?</div>
                        <router-link class="w-100" :to="{ name: 'login' }"
                            >Log In</router-link
                        >
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.boxy {
    width: 3rem;
    height: 3rem;
}

.column-container {
    display: flex;
}

.form-elem {
    display: block;
    text-align: left;
}

@media (max-width: 40rem) {
    .main-container {
        width: 90%;
        margin: 1rem auto 1rem auto;
    }

    .column1 {
        display: none;
    }

    .column2 {
        width: 100%;
    }
}

@media (min-width: 40rem) {
    .main-container {
        max-width: 50rem;
        margin: 5rem auto 0 auto;
    }

    .column1 {
        margin-right: 2rem;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
    }

    .column2 {
        width: 20rem;
    }
}
</style>

<script>
export default {
    name: "RegisterView",
    data: function() {
        return {
            alertText: "",
            loading: false,
            username: "",
            password1: "",
            password2: ""
        };
    },
    computed: {
        fieldsAreEmpty() {
            return this.username.trim() == "" || this.password1.trim() == "";
        }
    },
    methods: {
        async clickCreateAccount() {
            // Do the passwords match?
            if (this.password1 != this.password2) {
                this.alertText = "The passwords do not match.";
                return;
            }

            // Has this user been referred by another one?
            var introducedBy = this.$router.history.current.query.ref;

            if (introducedBy == undefined) {
                introducedBy = null;
            }

            // Reset state
            this.loading = true;
            this.alertText = "";

            // Register
            const response = await this.$store.dispatch("createNewAccount", {
                username: this.username,
                password: this.password1,
                introducedBy: introducedBy
            });

            this.loading = false;

            // There was a problem - report it
            if (!response.success) {
                this.alertText = response.message;
                return;
            }
        }
    },
    mounted() {
        // Set default values if provided
        const query = this.$router.history.current.query;

        if (query.username != undefined) {
            this.username = query.username;
        }
    }
};
</script>
