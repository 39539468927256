<template>
    <div>
        <form>
            <div
                class="alert alert-danger"
                role="alert"
                v-if="alertText.length != 0"
            >
                {{ alertText }}
            </div>

            <div class="form-group row">
                <label for="name" class="col-sm-3 col-form-label">Name</label>
                <div class="col-sm-9">
                    <input
                        type="text"
                        class="form-control"
                        id="name"
                        v-model="product.name"
                    />
                </div>
            </div>

            <div class="form-group row" v-if="this.product.productId != null">
                <label for="image" class="col-sm-3 col-form-label">Image</label>
                <div class="col-sm-9">
                    <button
                        type="button"
                        class="image-selector btn btn-dark"
                        @click="imageButtonClick"
                    >
                        <img
                            :src="productImageUrl"
                            alt="product image"
                            class="product-image mt-2"
                            height="140rem"
                            v-if="!userHasChangedProductImage"
                        />
                        <div class="mt-2">Select Image</div>
                    </button>
                </div>
            </div>

            <input
                type="file"
                class="d-none"
                ref="hiddenFileSelector"
                @change="hiddenFileSelectorChange"
                accept="image/jpeg,image/png"
            />

            <div class="form-group row">
                <label for="description" class="col-sm-3 col-form-label"
                    >Description</label
                >
                <div class="col-sm-9">
                    <textarea
                        rows="5"
                        class="form-control"
                        id="description"
                        v-model="product.description"
                    ></textarea>
                </div>
            </div>

            <div class="form-group row">
                <label for="price" class="col-sm-3 col-form-label"
                    >Price (USD)</label
                >
                <div class="col-sm-9">
                    <input
                        type="number"
                        min="0.01"
                        max="10000"
                        class="form-control"
                        id="price"
                        v-model="priceInDollars"
                    />
                </div>
            </div>

            <div class="form-group row" v-if="!essentialsOnly">
                <label for="autoPost" class="col-sm-3 col-form-label"
                    >Auto-post</label
                >
                <div class="col-sm-9">
                    <select
                        name="autoPost"
                        id="autoPost"
                        class="form-control"
                        v-model="autoPostString"
                    >
                        <option>yes</option>
                        <option>no</option>
                    </select>
                </div>
            </div>

            <div class="form-group row" v-if="!essentialsOnly">
                <label for="Category" class="col-sm-3 col-form-label"
                    >Category</label
                >
                <div class="col-sm-9">
                    <select
                        name="category"
                        id="category"
                        class="form-control"
                        v-model="product.category"
                    >
                        <option v-for="(x, i) in productCategories" :key="i">{{
                            x
                        }}</option>
                    </select>
                </div>
            </div>

            <div class="form-group row" v-if="!essentialsOnly">
                <label for="upc" class="col-sm-3 col-form-label">UPC</label>
                <div class="col-sm-9">
                    <select
                        name="upc"
                        id="upc"
                        class="form-control"
                        v-model="product.upc"
                    >
                        <option v-for="(upc, i) in upcInformation" :key="i">{{
                            upc.upcId
                        }}</option>
                    </select>
                </div>
            </div>

            <div
                class="alert alert-warning"
                role="alert"
                v-if="showAutoDeliveryWarning"
            >
                Your subscription does not include auto-delivery. The product
                will not be posted.
            </div>

            <div class="form-group row" v-if="!essentialsOnly">
                <label for="deliveryMethod" class="col-sm-3 col-form-label"
                    >Delivery Method</label
                >
                <div class="col-sm-9">
                    <select
                        name="deliveryMethod"
                        id="deliveryMethod"
                        class="form-control"
                        v-model="product.deliveryMethod"
                    >
                        <option
                            v-for="(x, i) in productDeliveryMethods"
                            :key="i"
                            >{{ x }}</option
                        >
                    </select>
                </div>
            </div>

            <div class="form-group row" v-if="!essentialsOnly">
                <label for="platform" class="col-sm-3 col-form-label"
                    >Platform</label
                >
                <div class="col-sm-9">
                    <select
                        name="platform"
                        id="platform"
                        class="form-control"
                        v-model="product.platform"
                    >
                        <option v-for="(x, i) in productPlatforms" :key="i">{{
                            x
                        }}</option>
                    </select>
                </div>
            </div>

            <div class="form-group row" v-if="!essentialsOnly">
                <label for="shipsInDays" class="col-sm-3 col-form-label"
                    >Days to ship</label
                >
                <div class="col-sm-9">
                    <input
                        type="number"
                        min="1"
                        max="30"
                        class="form-control"
                        id="shipsInDays"
                        v-model="product.shipsInDays"
                    />
                </div>
            </div>

            <div class="form-group row" v-if="!essentialsOnly">
                <label for="expiresInDays" class="col-sm-3 col-form-label"
                    >Days to expire</label
                >
                <div class="col-sm-9">
                    <input
                        type="number"
                        min="1"
                        max="30"
                        class="form-control"
                        id="expiresInDays"
                        v-model="product.expiresInDays"
                    />
                </div>
            </div>
            <div v-if="!essentialsOnly">
                <div
                    class="form-group row"
                    v-for="index in tagList.length"
                    :key="index"
                >
                    <label class="col-sm-3 col-form-label"
                        >Tag {{ index }}</label
                    >
                    <div class="col-sm-4">
                        <input
                            type="text"
                            class="form-control"
                            v-model="tagList[index - 1][0]"
                            @input="tagChanged(index - 1)"
                        />
                    </div>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            v-model="tagList[index - 1][1]"
                            @input="tagChanged(index - 1)"
                        />
                    </div>
                </div>
            </div>
            <div
                v-if="
                    !essentialsOnly && product.deliveryMethod == 'auto-delivery'
                "
            >
                <h5>Codes for Auto-Delivery</h5>
                <div class="container-fluid">
                    <div
                        class="row mb-1"
                        v-for="(code, ii) in product.codes"
                        :key="code.code"
                    >
                        <div class="col-8">
                            {{ code.code }}
                        </div>
                        <div class="col-4">
                            <i v-if="code.status == 'sold'">
                                sold
                            </i>
                            <button
                                type="button"
                                class="btn btn-danger w-100"
                                v-else
                                @click="product.codes.splice(ii, 1)"
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <input
                                type="text"
                                class="form-control"
                                v-model="codeToAdd"
                            />
                        </div>
                        <div class="col-4">
                            <button
                                type="button"
                                class="btn btn-secondary w-100"
                                :disabled="codeToAdd.trim().length == 0"
                                @click="addCode()"
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<style scoped>
.product-image {
    margin-left: auto;
    margin-right: auto;
}
</style>

<script>
import $ from "jquery";
import { apiRequest } from "../main";

export default {
    name: "ProductEditor",
    computed: {
        productCategories() {
            return this.$store.state.productCategories;
        },
        upcInformation() {
            return this.$store.state.upcInformation;
        },
        productDeliveryMethods() {
            return this.$store.state.productDeliveryMethods;
        },
        productPlatforms() {
            return this.$store.state.productPlatforms;
        },
        productImageUrl() {
            // Browsers cache the product image, not realizing that it has
            // changed on the server when the user edits a product. By adding a
            // changing query to the URL the browser is forced to reload the
            // image.
            return `/api/product/${this.product.productId}/image?ignoredQuery=${this.imageReloadTag}`;
        },
        showAutoDeliveryWarning() {
            let subTierName = this.$store.state.profile.subscriptionTier;
            let subTierInfo = this.$store.state.subscriptionTiers[subTierName];
            return (
                this.product.deliveryMethod == "auto-delivery" &&
                !subTierInfo.allowAutoDelivery
            );
        }
    },
    props: {
        essentialsOnly: {
            default: false,
            type: Boolean
        }
    },
    data: function() {
        return {
            alertText: "",
            userHasChangedProductImage: false,
            imageReloadTag: 0,

            priceInDollars: 1.0,
            autoPostString: "yes",
            tagList: [],
            codeToAdd: "",

            product: {
                category: "ingame-item",
                deliveryMethod: "coordinated-transfer",
                description: "",
                autoPost: true,
                expiresInDays: 7,
                gameflipName: null,
                productId: null,
                name: "",
                platform: "unknown",
                priceInCents: 100,
                shipsInDays: 1,
                tags: {},
                upc: "none",
                meta: {},
                codes: []
            }
        };
    },
    methods: {
        tagChanged(index) {
            const pair = this.tagList[index];
            const key = pair[0];
            const value = pair[1];

            const empty = key.length == 0 && value.length == 0;
            const isLast = index == this.tagList.length - 1;

            // Update the product; Delete the tag if it's empty
            if (empty && !isLast) {
                this.$delete(this.tagList, index);
            } else {
                this.$set(this.tagList, index, [key, value]);
            }

            // Always keep around one more field than technically required
            if (isLast && !empty) {
                this.tagList.push(["", ""]);
            }
        },
        async postProductImage() {
            const productImage = this.$refs.hiddenFileSelector.files[0];
            const formData = new FormData();
            formData.append("productImage", productImage);

            const result = await apiRequest(
                "POST",
                this.productImageUrl,
                formData
            );

            // Force reload the displayed image
            this.forceReloadImage();

            return result;
        },
        getProduct() {
            // Most values are updated straight in this.product. Some are not
            // however, so these need to be added.
            const result = Object.assign({}, this.product);

            // Parse numeric inputs
            result.priceInCents = Math.round(this.priceInDollars * 100);
            result.shipsInDays = parseInt(this.product.shipsInDays);
            result.expiresInDays = parseInt(this.product.expiresInDays);

            // AutoPost
            result.autoPost = this.autoPostString == "yes";

            // Tags
            result.tags = {};
            for (var ii = 0; ii < this.tagList.length; ++ii) {
                const pair = this.tagList[ii];
                const key = pair[0];
                const value = pair[1];
                const empty = key.length == 0 && value.length == 0;

                if (!empty) {
                    result.tags[key] = value;
                }
            }

            return result;
        },
        updateProduct(newProduct, clearImage) {
            for (const key in newProduct) {
                this.$set(this.product, key, newProduct[key]);
            }

            // Update tags which are fast to compute
            this.priceInDollars = this.product.priceInCents / 100;

            // Update the auto post string
            if (newProduct.autoPost) {
                this.autoPostString = "yes";
            } else {
                this.autoPostString = "no";
            }

            // Update tags if necessary
            if ("tags" in newProduct) {
                this.tagList = [];

                for (const key in this.product.tags) {
                    this.tagList.push([key, this.product.tags[key]]);
                }

                this.tagList.push(["", ""]);
            }

            // Clear the image if requested
            if (clearImage) {
                this.userHasChangedProductImage = false;
            }

            // Reload the product image
            this.forceReloadImage();
        },
        imageButtonClick() {
            $(this.$refs.hiddenFileSelector).trigger("click");
        },
        hiddenFileSelectorChange() {
            this.userHasChangedProductImage =
                this.$refs.hiddenFileSelector.files.length != 0;
        },
        forceReloadImage() {
            this.imageReloadTag += 1;
        },
        addCode() {
            this.product.codes.push({
                code: this.codeToAdd,
                status: "unused"
            });

            this.codeToAdd = "";
        }
    }
};
</script>
